<template>
  <div style="height: 80%;">

    <!-- TODO 加载框  v-if="!allDeviceListLoaded"   isShowPopup-->
    <div id="loader-wrapper" v-if="isShowPopup">
      <div id="loader"></div>
      <div class="loader-section section-left"></div>
      <div class="loader-section section-right"></div>
      <div class="load_title">正在加载,请耐心等待
        <br>
        <!-- <span>V0.1</span> -->
      </div>
    </div>


    <!-- TODO 比赛数据列表 h4 改了成 div -->
    <el-card style="background-color: #ffffff00;">
      <div v-if="!this.showGroup" class="title" style="margin-bottom: 4px;">
        <el-row>
          <el-col class="totalText" :span="12">{{ pigeonRaceName }}</el-col>
           <!--赛鸽数量-->
          <el-col class="totalText" :span="12">{{$t('phoneLanguage.pigeonNum')}}:&nbsp;<span style="color:#3498DB;">{{ totalDev }}</span></el-col>

          <!-- <el-col class="offlineText" :span="6">{{offlineDev}}</el-col>
          <el-col class="onlineText" :span="6">{{onlineDev}}</el-col>
          <el-col class="runText" :span="6">{{runDev}}</el-col> -->
        </el-row>
      </div>

      <el-row>
        <el-col>
          <el-form :inline="true" :model="formData">
            <el-form-item style="margin-bottom: 2px;">
              <div style="display: flex;justify-content: space-between; align-items: center;">

                <div style="width: 100%; max-width: 205px;">  <el-input v-model="formData.name"
                    :placeholder="$t('phoneLanguage.inputEnterRankOrpigeonRingNumberSearchHint')"></el-input></div>

                <div style="margin-left: 5px;"> <el-button size="mini" type="primary"
                    @click="onSubmitSearchFormData">{{ $t('phoneLanguage.search') }}</el-button></div>
                    
                      <!-- <div> <el-button size="mini" type="primary"
                        @click="onSubmitSearchFormData">{{ $t('competition.btns.check') }}</el-button></div> -->
              </div>


            </el-form-item>
           
          </el-form>
          <!--已选数据： -->
          <span style="color: #ffffff; font-size: 15px;">{{$t('phoneLanguage.selectedData')}} <span style="color: #54ff00;">{{ selsectNubmer }}</span> {{ $t('phoneLanguage.strip') }}<el-button  @click="toggleSelection" style="margin-left: 5px;" type="info" icon="el-icon-delete" circle size="small"></el-button></span>
        </el-col>
      
        <!-- <el-col :span="8" type="flex" :align="'end'" justify="center">
            <el-button type="primary" @click="clickCreateButton">{{$t('competition.createCompetition')}}</el-button>
          </el-col> -->
      </el-row>
      <el-table size="mini" 
      :data="showDataList" :height="myHeight * 0.7" 
      ref="multipleTable"
      :border="true" class="child-table"
        :row-key="getRowKeys"
        @selection-change="handleSelectionChange"
        :header-cell-style="{ color: 'rgba(255,255,255,1)', textAlign: 'center' }"
        :cell-style="{ textAlign: 'center' }">
        <!-- <el-table-column fixed prop="index" :label="$t('common.sn')" width="80"></el-table-column> -->



        <el-table-column type="selection" width="55" :reserve-selection="true">
        </el-table-column>

        <!-- 排名 -->
        <el-table-column  :label="$t('phoneLanguage.ranking')"  prop="index" width="80">
        </el-table-column>

        <!-- <el-table-column  prop="deviceImei" :label="$t('competition.name')" ></el-table-column> -->
        <!-- <el-table-column  prop="deviceName" label="昵称" ></el-table-column> -->
         <!-- 鸽环号 -->
        <el-table-column prop="deviceImei" :label="$t('phoneLanguage.pigeonRingNumber')"></el-table-column>




      </el-table>
    </el-card>

    <!-- TODO 分页  .el-pagination {
    margin-top: 15px;
    position: absolute;
    bottom: 10px;
} -->
    <div class="pagination" style="position: absolute; bottom: 20px;">
      <el-pagination background :small="true" @size-change="handleSizeChange" @current-change="handleCurrentChange" 
        :pager-count="5" :current-page="pagination.currentPage" :page-sizes="[10, 20, 50, 100, 500, 1000]"
        :page-size="pagination.pageSize" layout=" sizes, prev, pager, next" :total="pagination.totalData">
      </el-pagination>
    </div>


    <div class="deviceList" :class="hasCollapse ? 'collapseList' : ''" v-if="false">
      <h4 v-if="!this.showGroup" class="title">
        <el-row>
          <!--赛鸽数量-->
          <el-col class="totalText" :span="12">赛鸽数量:&nbsp;<span style="color:#3498DB;">{{ totalDev }}</span></el-col>

          <!-- <el-col class="offlineText" :span="6">{{offlineDev}}</el-col>
          <el-col class="onlineText" :span="6">{{onlineDev}}</el-col>
          <el-col class="runText" :span="6">{{runDev}}</el-col> -->
        </el-row>
      </h4>

      <!-- TODO 改了 搜索框  @change="searchHandle" @blur="searchHandle"  -->
      <div class="searchInput">

        <el-input class="searchImei" :placeholder="$t('welcome.page.left.searchPlaceholder2')"
          suffix-icon="el-icon-search" v-model="searchDeviceImei">
        </el-input>
      </div>
      <div class="searchInput">
        <el-row type="flex" justify="space-around">
          <el-col :span="6">
            <!--TODO 全选按钮  -->
            <el-button type="success" :loading="selsectAllLoad" size="mini"
              @click="onAllSelect">{{ $t('welcome.page.left.allSelect') }}</el-button>
          </el-col>
          <el-col :span="6">
            <!-- 反选 -->
            <el-button type="danger" :loading="selectNOAll" size="mini"
              @click="onNoSelect">{{ $t('welcome.page.left.noSelect') }}</el-button>
          </el-col>
          <el-col :span="6" v-if="false">
            <!-- TODO 点击确认隐藏了 -->
            <el-button type="info" size="mini" @click="onConfirm">{{ $t('common.confirm') }},这里</el-button>
          </el-col>
        </el-row>
      </div>
      <!--  TODO 全部 可选 飞丢 allMenuList  allDeviceList -->
      <div class="searchInput tabWrap">
        <el-tabs class="allTree" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane :label="$t('welcome.page.left.labelAll')" name="all">

            <!-- filter-node-method  对树节点进行筛选时执行的方法，返回 true 表示这个节点可以显示，返回 false 则表示这个节点会被隐藏 -->
            <!-- :check-on-click-node="false"    是否在点击节点的时候选中节点，默认值为 false，即只有在点击复选框时才会选中节点。 -->
            <!-- @node-click="clickNode" 点击节点 事件 不要 -->
            <!-- default-checked-keys="index"  默认勾选的节点的 key 的数组	object  :default-checked-keys="allchildren" 
            -->

            <el-tree v-if="allDeviceListLoaded" ref="mTree" :data="allDeviceList" node-key="index" :accordion="false"
              :check-on-click-node="true" default-expand-all :default-checked-keys="computedCheckedKeys"
              :show-checkbox="true" @check="selectNode" :filter-node-method="mTreeFilterNode">
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <el-col :span="2">
                  <span class="itemClass">
                    <!-- <img class="treeIcon" :src="data.type === 1 ? dealerIcon : deviceIcon" alt=""> 
                      {{data.title}} 999
                    -->
                    {{ data.deviceImei }}
                    <el-link type="primary" icon="el-icon-edit" class="el-dropdown-link operation" :underline="false"
                      v-if="data.type === 1 && pwdType === '2'"
                      @click="toSoldDeviceManage(data)">{{ $t('devicePage.option.edit') }}</el-link>
                    <!-- 下拉操作列表 -->
                    <div v-if="data.type === 2 && pwdType === '2'">
                      <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link operation">
                          {{ $t('welcome.page.left.operation') }}
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item
                            :command="{ cmd: 'realTimeTracking', imei: data.deviceImei }">{{ $t('welcome.page.left.realTimeTracking') }}</el-dropdown-item>
                          <el-dropdown-item
                            :command="{ cmd: 'historicalTrack', imei: data.deviceImei }">{{ $t('welcome.page.left.historicalTrack') }}</el-dropdown-item>
                          <el-dropdown-item
                            :command="{ cmd: 'flightTraining', imei: data.deviceImei }">{{ $t('welcome.page.left.flightTraining') }}</el-dropdown-item>
                          <el-dropdown-item
                            :command="{ cmd: 'modifyConfig', imei: data.deviceImei }">{{ $t('devicePage.config') }}</el-dropdown-item>
                          <el-dropdown-item
                            :command="{ cmd: 'circularFence', imei: data.deviceImei }">{{ $t('welcome.page.left.circularFence') }}</el-dropdown-item>
                          <el-dropdown-item
                            :command="{ cmd: 'polygonalFence', imei: data.deviceImei }">{{ $t('welcome.page.left.polygonalFence') }}</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </span>
                </el-col>
                <el-col :span="2">
                  <div v-if="data.deviceImei !== undefined" :class="getColor(data)"></div>
                </el-col>
                <el-col :span="10">
                  <span style="text-align: left; "> {{ node.label }}</span>
                </el-col>
              </span>
            </el-tree>
          </el-tab-pane>


        </el-tabs>
      </div>
    </div>


  </div>
</template>

<script>
// 引入ccs
import '@/assets/css/mymediaload.css';

import { queryRaceDeviceList } from '@/api/requestUtils'
import {
  getMultipleDeviceTraceLog
} from '@/api/gpsDevice'

  import moment from 'moment'
  import 'moment-timezone';
const REQUEST_ROWS = 1000
export default {
  data() {
    return {
      myHeight: window.innerHeight, // 设备高度
      formData: { // 搜索框数据
        name: ''
      },
      pigeonRaceName: '',  // 鸽子赛名
      selsectNubmer: 0,  // 选中的鸽字数量
      pagination: { // 分页数据
        pageSize: 10,
        currentPage: 1,
        totalData: 0
      },
      hasCollapse: false,
      showGroup: false,
      searchDeviceImei: '',
      allDeviceList: [],// 所有设备
      showDataList: [],
      queryDeviceList: [],
      selsectAllLoad: false, // 全选加载动画
      selectNOAll: false,  // 不全动画
      activeName: 'all',
      allDeviceListLoaded: false, // 标记数据是否已加载完成
      isShowPopup: true, // 显示加载弹窗页面
      sendisHide: "", // 隐藏地图鸽子实体
      sendShowPigeons: "", // 显示地图鸽子实体
      sendDeviceImeiArray: [],
      selectedDevices: [],
      sendDeviceImei: '', // 传递设备号给cesiumMapContainer 不要了
      tabIndex: '0',
      // TODO  传给父组件的数据集合
      fromData: {
        pigeonNum: 0,
        all: '',
        noAll: '',
        selectImei: '',
        allDeviceImei: [],

      },
      raceId: '',
    }

  },
  created() {
    const beforeUnload = function () {

      sessionStorage.removeItem('deviceImei')
      sessionStorage.removeItem('dealerId')
      sessionStorage.removeItem('dealerName')
      sessionStorage.removeItem('historicalTrackDeviceImei')
      window.removeEventListener('beforeunload', beforeUnload)
    }
    window.addEventListener('beforeunload', beforeUnload)

    // 从 sessionStorage 获取语言设置，如果没有则默认为 'zh-CN'
    // const lang = window.sessionStorage.getItem('lang') || 'zh-CN';
    const lang = window.sessionStorage.getItem('lang');

    // 设置 Vue I18n 的当前语言
    this.$i18n.locale = lang;

    // 打印当前语言
    console.log("this.$i18n.locale", this.$i18n.locale);

    // this.$i18n.locale = window.sessionStorage.getItem('lang')
    // this.checkLang()
    // this.listAllUser()
    const raceId = sessionStorage.getItem('raceId')

    // 获取赛事名字
    this.pigeonRaceName = window.sessionStorage.getItem('historicalPigeonRaceName') || ''

    // console.log('raceId', raceId)

    if (raceId) {
      this.raceId = raceId
      this.onQueryRaceDeviceList()


    }


  },
  // beforeRouteLeave (to, from, next) {
  //   // TODO 计时器注释了
  //   clearInterval(this.intervalId)
  //   next()
  // },
  // TODO 监控搜索框中的数据
  watch: {
    // searchDeviceImei(val) {
    //   // console.log('搜索框中的数据', val)
    //   this.$refs.mTree.filter(val);
    //   // this.$refs.mAvailableTree.filter(val);
    //   // this.$refs.mLostTree.filter(val);
    // }
  },
  computed: {
    // TODO  全选
    computedCheckedKeys() {
      // 这里返回应该被默认选中的节点的 keys 数组
      // 注意：这个计算属性应该基于 allDeviceList 中的数据来动态生成
      // 由于您知道所有应该被选中的节点的 index，您可能需要在数据加载后设置这个数组
      // self.$refs.mTree.setCheckedKeys(self.allDeviceList?.map(node => node.index));
      // 但在这个例子中，我们假设您已经有了这个逻辑
      // console.log('allchildren-->', this.allchildren.filter(key => this.allDeviceList.some(node => node.index === key)))
      // console.log('allchildren-->', this.allDeviceList?.map(node => node.index));
      return this.allDeviceList?.map(node => node.index);
      // 注意：上面的逻辑可能不是您想要的，因为它会过滤掉不存在于 allDeviceList 中的 keys。
      // 您可能需要根据实际情况调整这个逻辑。
      // 如果 allchildren 应该直接映射到 allDeviceList 中的节点，那么您可能不需要这个计算属性，
      // 而是应该在数据加载后直接设置 allchildren。
    },
    totalDev: function () {


      //  return this.$t('welcome.page.left.deviceTotal') + this.deviceList.length + '  '
      return this.allDeviceList.length + '  '
    },
    getColor(data) {
      return function (data) {
        if (data === undefined || data === null || data === '') {
          return ''
        }
        if (data.statusText === undefined || data.statusText === null || data.statusText === '') {
          return ''
        }
        if (data.onlineStatus && data.lastMotionStatus === 1) {
          return 'statusRun'
        }
        if (data.onlineStatus) {
          return 'statusOnline'
        }
        return 'statusOffline'
      }
    },
  },
  methods: {

    handleSizeChange(pageSize) { // 分页:单页最大数量变化时的处理
      this.pagination.currentPage = 1
      this.pagination.pageSize = pageSize
      this.showDataList = this.allDeviceList.slice(0, pageSize)
    },
    handleCurrentChange(currentPage) { // 分页:当前页数变化时的处理
      this.pagination.currentPage = currentPage
      this.showDataList = this.allDeviceList.slice((currentPage - 1) * this.pagination.pageSize, currentPage * this.pagination.pageSize)
    },

    onSubmitSearchFormData() {
      if (this.formData.name && this.formData.name.trim()) {
        this.showDataList = []
        // this.allDeviceList.forEach(item => {
        // if (item.deviceImei.includes(this.formData.name) && item.index.includes(this.formData.name) ) {
        //   this.showDataList.push(item)
        // }
        // 使用 filter 方法简化代码，并移除对 index 的不必要检查
        this.showDataList = this.allDeviceList.filter(item => item.deviceImei.includes(this.formData.name.trim()) || item.index.toString().includes(this.formData.name.trim()));

        // })
      } else {
        this.showDataList = this.allDeviceList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)
      }
      // this.$message.error('找不到该比赛,请确认比赛名称是否正确')
    },

    // 全部
    mTreeFilterNode(value, data) {
      // console.log("全部-->",value,"---",data);
      if (!value) return true;
      return data.deviceImei.indexOf(value) !== -1;
      // return false;
    },
    // 记住已经选中的数据
    getRowKeys(row) {
      return row.index;
    },
    // 取消选择的数据
    toggleSelection(){
      this.$refs.multipleTable.clearSelection();
    
    },
    //TODO 点击表格选中事件
    handleSelectionChange(val) {
      console.log('选择表格', val)
this.selsectNubmer = val.length;
      // TODO 测试发请求
   // 子组件发送数据
   this.$emit('onEventigeonList', val)
   return;

      // TODO 开始 转圈圈
      this.isShowPopup = true;
      // TODO 发送请求获取轨迹数据
      // this.startSearchTrackLog(val)
    },
    startSearchTrackLog (listData) { //TODO 根据设备号 查询轨迹数据
        // this.queryDeviceList = this.allDeviceList;
        if( listData.length) {
           const eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
           const eventEndTime = window.sessionStorage.getItem('historicalTrackEndTime') || ''
         
          // let startTime =   moment(eventStartTime ).format("yyyy-MM-DD HH:mm:ss")
          // let endTime =   moment(eventEndTime  ).format("yyyy-MM-DD HH:mm:ss")
          
          let startTime =    moment.tz(eventStartTime, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
                let endTime =  moment.tz(eventEndTime, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');


          // TODO 这里赛事没有数据
        //   let startTime =   moment(new Date() ).format("yyyy-MM-DD 00:00:00")
          // let endTime =   moment(eventEndTime ).format("yyyy-MM-DD 23:59:59")
  
          console.log('开始查询轨迹数据 这里赛事没有数据--->', startTime, endTime)
          let jsonArray = []

          for (let i = listData.length - 1; i >= 0; i--) {
            // let deviceImei = listData[i]
            // if (this.deviceMap.has(deviceImei) && '1' === this.deviceMap.get(deviceImei)) {
              // let trackList = this.trackListMap.get(deviceImei)
              let jsonObject = {}
              jsonObject['deviceImei'] = listData[i].deviceImei
              jsonObject['startTime'] = startTime
              jsonObject['endTime'] = endTime
              jsonObject['requestRows'] = REQUEST_ROWS
              jsonObject['nextId'] = 0

              jsonArray.push(jsonObject)
         
          }
          console.log('拼装的请求数据jsonArray', jsonArray.length, jsonArray)
          // if (jsonArray.length) {
          //   this.onMultipleDeviceTraceLog(jsonArray)
          //   return
          // }
        // 发送请求
        this.onMultipleDeviceTraceLog(jsonArray);
         
          
        }else{
          // 没有选择数据
           // 子组件发送数据
           this.$emit('onEventigeonList', [])
           // 关闭转圈圈
           this.isShowPopup = false;
        }
        // for(let [key, value] of this.trackListMap) {
        //   this.addDeviceTrackMarker(key, value)
        // }
        // this.trackListMap.clear()
      },
      onMultipleDeviceTraceLog (jsonArray) {
      
      getMultipleDeviceTraceLog(jsonArray).then(res => {
        if (res.data.code === 0) {
          let dataList = res.data.data  // 每拿到数据 每条 数据 假设10个
          // console.log('轨迹数据dataList', dataList.length)
          if(dataList && dataList.length) {
            let isHasTrack = false
              // 循环 在从 list 中获取数据
                // 先重治数据
              // 没十条
              var testArrayData = [] ;

              dataList.forEach((element,rank) => {
                // 每个条设备 list
                // console.log('element轨迹数据', element.list)
              
                if(element && element.list && element.list.length) {
                  // console.log('element.list.length轨迹数据', element.list.length)
                  isHasTrack = true
                 
                  this.latLngDataTest = [];
                  
                  // 循环 每个条数据
                  element.list.forEach(item => {
//                     deviceImei 设备号
// deviceName 昵称
// ringNo  鸽环号

// altitude  高度
// gpsLocationTime 时间当期记录的时间
// lng, 经度
// lat, 纬度

// 弹出层中额外：
// speed  速度
// gpsTime： 记录时间



// deviceVol 电量
                    // TODO 拼装数据 
                    const lnglat = {
                      // 排名
                    rank: item?.rank ? item.rank : rank+1,
                    logId: item.logId,
                    deviceImei:item.deviceImei,
                    deviceName:item.deviceName,
                    lng: item.lng, lat: item.lat,
                    altitude: item?.altitude ? item.altitude + 1 : 1,
                    ringNo:item?.ringNo || 0,
                    // gpsLocationTime: item.gpsLocationTime,
                    // TODO 这里 需要优化 转换时间
                    gpsLocationTime: this.setStringTimeToTimeStamp(item.gpsTime),
                    
                    speed: item?.speed ? item.speed : 0,
                    gpsTime: item.gpsTime,
                    deviceVol: item?.deviceVol ? item.deviceVol : 0,
                   
                  };

                  // console.log('拼装后的每次10条？lnglat', lnglat)
                  this.latLngDataTest.push(lnglat)

                  })
                
               

                  testArrayData.push(this.latLngDataTest)
                }
              
              })
                  
          // 子组件发送数据
          this.$emit('onEventigeonList', testArrayData)
          }

         
          // console.log('拼装后的经纬度数据为:-home', this.latLngDataTest)
            console.log('拼装后的每次10条？', testArrayData)
          

        }
       

        // this.queryDeviceList.splice(0, this.queryDeviceList.length)
        // this.startSearchTrackLog()
        // 关闭转圈圈
        this.isShowPopup = false;

      }).catch(e => {
        console.log('轨迹数据查询失败',e)
        // this.queryDeviceList.splice(0, this.queryDeviceList.length)
        // this.startSearchTrackLog()
         // 关闭转圈圈
        this.isShowPopup = false;
        //  提示失败
        this.$message.error('轨迹数据查询失败')
       
      })
    },
          // TODO 工具类 时间格式转 时间戳
          setStringTimeToTimeStamp(dateString) {
        const date = new Date(dateString);
        const timestamp = date.getTime(); // 获取时间戳（毫秒）
        return timestamp;
      },
    selectNode(data, node) { // TODO 选择节点
      //  console.log('选择节点', data, "--->",node)
      // this.sendDeviceImeiArray = node.checkedNodes;
      //   重置数据
      this.fromData = {};

      this.selectedDevices = []
      let sendData = []
      node.checkedNodes.forEach(item => {

        // deviceType || type
        if (item.deviceType === 2) {
          sendData.push(item.deviceImei);
          this.selectedDevices.push(item.deviceImei)
        }
      })
      //   this.sendDeviceImeiArray = sendData
      this.fromData.allDeviceImei = sendData
      //   fromData:{
      //         all:'',
      //         noAll:'',
      //         selectImei:'',
      //         allDeviceImei:[],

      //     },
      //   // TODO 给父组件传递数据 
      this.$emit('onEventigeonList', this.fromData)
      //   console.log('选择节点sendDeviceImeiArray', this.sendDeviceImeiArray)
    },
    clickNode(node, data, value) { // TODO 点击节点
      let deviceImei = node.deviceImei
      //   重置数据
      this.fromData = {};

      // 生成一个根据当前时间按随机的字符串长度14位 
      let randomStr = Math.random().toString(36).substring(2, 14);

      // 传递设备号给地图
      // this.sendDeviceImei = deviceImei + '_' + randomStr;
      this.fromData.selectImei = deviceImei + '_' + randomStr;

      //   fromData:{
      //         all:'',
      //         noAll:'',
      //         selectImei:'',
      //         allDeviceImei:[],

      //     },

      //把选择的设备号传递给父组件
      //   // TODO 给父组件传递数据 
      this.$emit('onEventigeonList', this.fromData)
      // console.log('点击节点deviceImei', deviceImei,node, data, value)
      //   if(deviceImei && deviceImei.length) {
      //     let markerList = this.deviceTrackMap.get(deviceImei)
      //     if(markerList && markerList.length) {
      //       let marker = markerList[markerList.length - 1]
      //       let point = {
      //         lat: marker.deviceObj.lat,
      //         lng: marker.deviceObj.lng
      //       }
      //       // if (this.mapInstance.currentOpenInfoWindow !== undefined && this.mapInstance.currentOpenInfoWindow !== null) {
      //       //   this.mapInstance.currentOpenInfoWindow.infoWindow.close()
      //       // }
      //       // marker.infoWindow.open()
      //     //   moveToCenter(this.mapInstance, point)
      //     }
      //   }
      // if (data.data.title && data.childNodes.length === 0) {
      //   openInfoWindowByDeviceImei(this.mapInstance, data.data.title)
      // }
    },
    // 搜索框
    searchHandle() {
      this.$refs.mTree.filter(this.searchDeviceImei);
    },
    // TODO 点击了全选
    onAllSelect() {
      this.selsectAllLoad = true; // 全选加载动画 没有效果

      //   重置数据
      this.fromData.all = '';
      this.fromData.noAll = '';
      // 生成一个根据当前时间按随机的字符串长度14位 
      let randomStr = Math.random().toString(36).substring(2, 14);


      // let randomStr = Math.floor(Math.random() * 10000).toString();
      //   this.sendShowPigeons = true +'_'+randomStr; // 显示鸽子实体
      this.fromData.all = true + '_' + randomStr;

      //   fromData:{
      //         all:'',
      //         noAll:'',
      //         selectImei:'',
      //         allDeviceImei:[],

      //     },


      //   // TODO 给父组件传递数据 
      this.$emit('onEventigeonList', this.fromData)

      const self = this
      this.$nextTick(() => {
        // console.log(self.tabIndex, self.$refs.mTree.getCheckedKeys())
        if (self.tabIndex === '0') {
          self.$refs.mTree.setCheckedKeys(self.allDeviceList?.map(node => node.index));

          //  console.log(self.tabIndex, self.$refs.mTree.getCheckedKeys().length)
          this.selsectAllLoad = false; // 全选加载动画
        } else if (self.tabIndex === '1') {
          self.$refs.mAvailableTree.setCheckedKeys(['0'], false)
        } else if (self.tabIndex === '2') {
          self.$refs.mLostTree.setCheckedKeys(['0'], false)
        }
        //  else if(self.tabIndex === '0') {
        //   self.$refs.mTree.setCheckedKeys(['0'], false)
        // }
      })


    },
    //   TODO  不全选
    onNoSelect() {
      this.selectNOAll = true; // 加载动画 没有效果
      //   重置数据
      this.fromData.noAll = '';
      this.fromData.all = '';

      // 发送数到地图 通知把鸽子都隐藏掉
      // 生成一个根据当前时间按随机的字符串 通知地图隐藏
      // let randomStr = Math.floor(Math.random() * 10000).toString();
      // 生成一个根据当前时间按随机的字符串长度14位  每次会执行
      let randomStr = Math.random().toString(36).substring(2, 14);
      this.sendisHide = true + '_' + randomStr;

      this.fromData.noAll = true + '_' + randomStr;

      //   fromData:{
      //         all:'',
      //         noAll:'',
      //         selectImei:'',
      //         allDeviceImei:[],

      //     },


      //   // TODO 给父组件传递数据 
      this.$emit('onEventigeonList', this.fromData)

      const self = this
      this.$nextTick(() => {
        if (self.tabIndex === '0') {
          self.$refs.mTree.setCheckedNodes([]);
          this.selectNOAll = false; // 加载动画
        } else if (self.tabIndex === '1') {
          self.$refs.mAvailableTree.setCheckedNodes([])
        } else if (self.tabIndex === '2') {
          self.$refs.mLostTree.setCheckedNodes([])
        } else {
          self.$refs.mTree.setCheckedNodes([])
        }
      })


    },
    handleClick(tab, event) {
      this.tabIndex = tab.index
    },

    // TODO 获取数据 等待数据加载完成
    async onQueryRaceDeviceList() {
      await queryRaceDeviceList(this.raceId)
        .then((res) => {
          if (res.data.code === 0 && res.data.data) {


            this.dataList = []
            this.showDataList = []
            if (res.data.data[0]) {

              // TODO 把鸽子数量保存的session中
              window.sessionStorage.setItem('pigeonNum', res.data.data[0].length)
              console.log('请求介乎哦鸽子数量', res.data.data[0].length)
              // TODO 给父组件传递数据 
              // this.fromData.pigeonNum = res.data.data[0].length
              // this.$emit('onEventigeonList', this.fromData)

              res.data.data[0].forEach((item) => {
                item.index = this.dataList.length + 1
                // item.key = 0

                this.dataList.push(item)
                this.allDeviceList.push(item)
                this.queryDeviceList.push(item.deviceImei)
              })
            }

            this.pagination.totalData = this.allDeviceList.length
            this.showDataList = this.allDeviceList.slice(this.pagination.pageSize * (this.pagination.currentPage - 1), this.pagination.pageSize * this.pagination.currentPage)

            if (!this.showDataList.length) {
              this.showDataList = this.allDeviceList.slice(0, this.pagination.pageSize)
            }
            this.isShowPopup = false; // 隐藏加载动画
            // console.log("this.showDataList", this.showDataList)



          }
          this.allDeviceListLoaded = true; // 标记数据已加载
          // TODO 赛事设备
          // console.log('赛事设备', this.allDeviceList,this.queryDeviceList.length)
          // this.allchildren = this.allDeviceList?.map(node => node.index)
          // console.log('赛事设备', this.allchildren,this.allchildren.length)
          // this.allchildren = this.allDeviceList?.map(node => node.index)
          //  console.log('赛事设备1111-->', this.allchildren,this.allchildren.length)
          this.startSearchTrackLog()

        })
        .catch((e) => { })
    },



  },

};
</script>

<style lang="less" scoped>
.deviceList {
  width: 100%;
  height: 98% !important;
  float: left;
  background-color: #052531;
  border-radius: 10px;
  border: 1px solid #ddd;
}

.deviceList.collapseList {
  display: none;
}

.totalText {
  font-size: 15px;
  color: #ffffff;
}

.statusOnline {
  border: 1px solid red;
  width: 4px;
  height: 4px;
  background-color: red;
  margin-top: 6px;
  border-radius: 3px;
}

.statusRun {
  border: 1px solid green;
  width: 4px;
  height: 4px;
  background-color: green;
  margin-top: 6px;
  border-radius: 3px;
}

.statusOffline {
  border: 1px solid #fcd820;
  width: 4px;
  height: 4px;
  background-color: #fcd820;
  margin-top: 6px;
  border-radius: 3px;
}

.searchInput {
  width: 94%;
  margin-top: 4px;
}

.tabWrap {
  height: calc(100% - 160px);

  .allTree {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  /deep/ .el-tabs__content {
    flex: 1;
  }

  .el-tab-pane {
    height: 100%;
  }



  .el-tree {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    background-color: #052531;
    color: #ccc;
  }

  /deep/ .el-tree-node__children {
    overflow: visible !important;
  }

  // :deep .el-tree-node{
  //   height: 100%;
  //   // 横向滚动条
  //   // el-tree-node__children的样式默认overflow:hidden
  //   // 继承宽度，当宽度超出时隐藏，固不显示横向滚动
  //   .el-tree-node__children{
  //     overflow: visible;
  //   }

  // }
  // :deep .el-tree-node__children{
  //     overflow: visible !important;
  //   }
  // ::v-deep {
  //高宽分别对应横竖滚动条的尺寸
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  //定义滚动条轨道 内阴影 圆角
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  //定义滑块 内阴影 圆角
  ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #3498dc;
  }

}

::v-deep {

  .el-table,
  .el-table__expanded-cell {
    background-color: #ffffff00;
  }

  .el-table th.el-table__cell {
    background-color: #ffffff00;
  }

  .el-table thead {
    color: #ffffff;
  }

  .el-table tr {
    background-color: #ffffff00;
    color: #ffffff;
  }

  .el-table__empty-text {

    color: #ffffff;
  }



}

/**移动端样式  */
@media screen and (max-width: 768px) {

//   ::v-deep {
//     // .el-table th.el-table__cell {
//     //     user-select: initial;
//     .el-pagination {
  
//   position: absolute;
//   bottom: 10 !important;
// }

        // }
  .el-table {
    margin-top: 1px;
    height: 408px !important;
    ;
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
  }

  .el-table,
  .el-table__expanded-cell {
    background-color: #ffffff00;
  }

  .el-table th.el-table__cell {
    background-color: #ffffff00;
  }

  .el-table thead {
    color: #ffffff;
  }

  .el-table tr {
    background-color: #ffffff00;
    color: #ffffff;
  }

  .el-table__empty-text {

    color: #ffffff;
  }



  // }
}




</style>