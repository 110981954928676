<template>
    <div style="height: 100%;">

        <!-- TODO 加载框  v-if="!allDeviceListLoaded"   isShowPopup-->
        <div id="loader-wrapper" v-if="isShowPopup">
            <div id="loader"></div>
            <div class="loader-section section-left"></div>
            <div class="loader-section section-right"></div>
            <div class="load_title">{{ $t('phoneLanguage.loading') }}
                <br>
                <!-- <span>V0.1</span> -->
            </div>
        </div>

        <!-- ˙直播平台页面 -->
        <!-- 主地图容器 -->
        <div id='body_div' class='body_div'>
            <div id="cesiumContainer" style="height: 100%; width: 100%;"></div>
        </div>

        <!-- 小地图容器 -->
        <div v-show="isCesium2DVisible" class="cesium2D-wrapper">
            <div class="mask-layer">
            </div>
            <div id="cesiumContainer2D" :style="{ 'border-color': borderColor2D }"></div>
        </div>

        <!-- <div id="cesiumContainer2D" style="height: 100%; width: 100%;" v-show="false"></div> -->

        <!-- TODO 排名数据 -->
        <div v-if="isShowRanking">
            <div class="echelonBox on" data-v-e6b6cb14="" @mouseover="isShowRankingHovered = true"
                @mouseleave="isShowRankingHovered = false" :style="{
                    backgroundColor: isShowRankingHovered ? '#23222278' : 'rgba(255, 255, 255, 0.1)'
                }">



                <div>
                    <!-- 搜索框独立成行 请输入鸽环号/名次  -->
                    <el-input v-model="searchVal"
                        :placeholder="$t('phoneLanguage.inputEnterRankOrpigeonRingNumberSearchHint')"
                        class="table-search-input" style="width: 100%; max-width: 600px; margin: 0 auto;" />
                </div>

                <el-table :data="filteredData" border size="mini" class="table-ranking" @row-click="handleCurrentChange"
                    :header-cell-style="{ color: 'rgba(255,255,255,1)', textAlign: 'center' }"
                    :cell-style="{ textAlign: 'center' }">


                    <!-- 排名 有输入用这个 -->
                    <el-table-column :label="$t('phoneLanguage.ranking')" width="50" prop="index" v-if="searchVal">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center;">
                                <div
                                    :class="[scope.row.index === 1 ? 'index_one' : scope.row.index === 2 ? 'index_two' : scope.row.index === 3 ? 'index_three' : '']">
                                    {{ scope.row.index }}
                                </div>
                                <div style="display: flex; align-items: center; margin-left: 5px;">
                                    <img class="index_common"
                                        :src="scope.row.index === 1 ? '/mapGLB/gold.png' : scope.row.index === 2 ? '/mapGLB/silver.png' : scope.row.index === 3 ? '/mapGLB/copper.png' : ''"
                                        v-if="scope.row.index <= 3" alt="" />
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <!-- 排名 没有输入搜索值用这个-->
                    <el-table-column :label="$t('computerLanguage.ranking')" width="50" v-if="!searchVal">
                        <template slot-scope="scope">
                            <div style="display: flex; justify-content: center;">
                                <div
                                    v-bind:class="[scope.$index + 1 == '1' ? 'index_one' : scope.$index + 1 == '2' ? 'index_two' : scope.$index + 1 == '3' ? 'index_three' : '']">
                                    {{ scope.$index + 1 }}</div>
                                <div style="display: flex; align-items: center;">
                                    <img class="index_common"
                                        v-bind:src="[scope.$index + 1 == '1' ? '/mapGLB/gold.png' : scope.$index + 1 == '2' ? '/mapGLB/silver.png' : scope.$index + 1 == '3' ? '/mapGLB/copper.png' : 'none']"
                                        v-if="scope.$index + 1 <= 3">
                                </div>
                            </div>
                        </template>
                    </el-table-column>


                    <!-- 鸽环号 -->
                    <el-table-column :label="$t('phoneLanguage.pigeonRingNumber')" prop="deviceImei" width="132">
                    </el-table-column>

                    <!-- 速度(m/min) -->
                    <el-table-column :label="$t('phoneLanguage.speed')" prop="speed" width="91"></el-table-column>
                    <!-- 海拔，高度(m) -->
                    <el-table-column :label="$t('phoneLanguage.altitude')" prop="height"></el-table-column>
                    <!-- 离终点(km) -->
                    <el-table-column :label="$t('phoneLanguage.distanceToDestination')" width="103"
                        prop="endDistance"></el-table-column>
                    <!-- 累积时间 -->
                    <el-table-column :label="$t('phoneLanguage.accumulateTime')" prop="gpsLocationTime">
                    </el-table-column>

                    <!-- 停留时间 stayTime-->
                    <el-table-column :label="$t('phoneLanguage.stayTime')" prop="stayTime">
                    </el-table-column>




                </el-table>


            </div>
        </div>


        <!-- 地图切换按钮-->
        <div class="mapSwitchBox">



            <div class="mapSwitch" @click="toggleMapMode()">
                <img class="mapSwitchItem" src="/mapGLB/2d-icon.png" alt="2D">
            </div>

            <!-- 留地 图影像切换 隐藏显示点位点  alt="隐藏/显示点位"-->
            <div class="mapSwitch" @click="ShowbaseLayerPicker()" v-if="false">
                <img class="mapSwitchItem" :src="isShowbaseLayerPicker ? '/mapGLB/3d-icon.png' : '/mapGLB/2d-icon.png'">
            </div>


            <!-- 显示排名  alt="隐藏/显示排名" -->
            <div class="mapSwitch" @click="showPigeonRankingPopup()">
                <img class="mapSwitchItem" :src="isShowRanking ? '/mapGLB/ranking_on.png' : '/mapGLB/ranking_off.png'">
            </div>

            <!-- 隐藏显示风场  alt="隐藏/显示2D" -->
            <div class="mapSwitch" @click="showWindy()" v-if="iswindy">
                <img class="mapSwitchItem" :src="iswindyVisible ? '/mapGLB/windy_on.png' : '/mapGLB/windy_off.png'">
            </div>

            <!-- 隐藏显示2d小地图 alt="隐藏/显示2D" -->
            <div class="mapSwitch" @click="showMarkerMapMode()">
                <img class="mapSwitchItem" :src="isCesium2DVisible ? '/mapGLB/map2D_on.png' : '/mapGLB/map2D_off.png'">
            </div>

            <!-- 是否全屏 alt="全屏/不全屏" -->

            <div class="mapSwitch" @click="isFullScreen()">
                <img class="mapSwitchItem"
                    :src="isShowFullscreenButton ? '/mapGLB/fullScreen_on.png' : '/mapGLB/fullScreen_off.png'">
            </div>
           


        </div>
        <div class="mapGameBox">
            <!-- 比赛数据 按钮 alt="比赛数据" -->
            <div class="mapSwitch" @click="isShowGameData()">
                <img class="mapSwitchItem"
                    :src="isShowGameDataButton ? '/mapGLB/gameData_on.png' : '/mapGLB/gameData_off.png'">
            </div>
        </div>


        <!-- <el-row>
      
        <el-col :lg="12" :xs="20" class="lightgreen-box">
        </el-col>

</el-row> -->
        <!-- 展示赛事列表 -->
        <div class="mapGameListBox" v-if="isShowGameDataButton" >

         
            <!-- 关闭  @click="closeGameDataList"  -->
            <div class="closePlay2 " @click="closeGameDataList">
                <img src="/mapGLB/close_on.png" alt="关闭" />
                <!-- <i class="el-icon" @click="closeGameDataList">
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                        </path>
                    </svg>
                </i> -->
            </div>

            <!-- 导入数据列表 主组件-->
            <!-- <keep-alive>    </keep-alive>   -->
             <!-- {{  myWidth }}--{{ myHeight }} -->
            <GameListData @onEvent="getHandle"></GameListData>

        </div>

        <!-- 展示鸽子数据 @click="closePigeonDataList" -->
        <div class="mapGameListBox" v-if="isShowPigeonData" v-show="isShow">

            <!-- <span style="color: #fff;">{{ endText }}
                {{ startText }}
                {{ startText }} =====
                {{ $t('phoneLanguage.startPoint') }}
                {{ $t('phoneLanguage.endPoint') }}
                {{ $t('phoneLanguage.kilometer') }}

            

            </span> -->

            <!-- 关闭  isShowPigeonData -->
            <div class="closePlay2" @click="closePigeonDataList">
                <img src="/mapGLB/close_on.png" alt="关闭" />
                <!-- <i class="el-icon" @click="closePigeonDataList">
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                        <path fill="currentColor"
                            d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z">
                        </path>
                    </svg>
                </i> -->
            </div>
            <div style="padding-top: 5px;display: flex;
    justify-content: space-evenly;">
                <!-- 返回赛事 -->
                <el-button type="success" size="mini" @click="closePigeonData">{{ $t('phoneLanguage.backToMatch')
                    }}</el-button>

                <!-- 查看轨迹 -->
                <span>
                    <el-button type="primary" size="mini" @click="showTrajectoryMode">{{
                        $t('phoneLanguage.viewTrajectory')
                        }}</el-button>


                    <el-switch class="switchStyle" v-model="map2Dor3D" :active-value="1" :inactive-value="0"
                        active-text="2D" inactive-text="3D" active-color="#13ce66" inactive-color="#ff4949">
                    </el-switch>

                </span>

                <!-- 雷达模式 -->
                <el-button type="danger" size="mini" @click="showRadarMode">{{ $t('phoneLanguage.radarMode')
                    }}</el-button>
            </div>

            <!-- <button @click="closePigeonData">返回</button> -->
            <!-- <h4 style="color: #fff;">{{pigeonRaceName}}123</h4> -->
            <br />
            <!-- <keep-alive>
             <PigeonListData @onEventigeonList="getigeonListHandle" />
            </keep-alive> -->
            <PigeonListData @onEventigeonList="getigeonListHandle" />
        </div>


    </div>
</template>

<script>
// import * as Cesium from 'Cesium'
// 所有数据 
// import MyDataSource from '@/assets/data/datatest.json';

// 简单数据
// import MyDataSource from '@/assets/data/test.json';


// 1 ok 867585332211376
// import TrajectoryData from '@/assets/data/pigeon_1.json';

// 8 ok
// import TrajectoryData from '@/assets/data/pigeon_5.json';
// 15 ok
// import TrajectoryData from '@/assets/data/pigeon_20.json';
// 引入轨迹数据 全部鸽子
// import TrajectoryData from '@/assets/data/datatest.json';
// 引入弹出窗口ccs
import '@/assets/css/mymediaload.css';

// 引入ccs 适应手机屏幕 消除原有样式
import '@/assets/css/mywindow.css';
// 下雪
//  import { handleSnow, removeStage } from '@/assets/js/weatherUtil.js';

// 起风数据 OK tai
// import WindyDataJson from '@/assets/data/tai.json';
import WindyDataJson from '@/assets/data/wind.json';


// //起风
// import Windy from '@/assets/js/Windy.js';
// import CanvasWindy from '@/assets/js/Windy3.js'; // 引入 起风数据


import GameListData from '@/components/cesiumMap/gameListData.vue';
import PigeonListData from '@/components/cesiumMap/pigeonListData.vue';
import {
    getMultipleDeviceTraceLog
} from '@/api/gpsDevice'

import moment from 'moment'
import 'moment-timezone';

import axios from 'axios'
// 查询条数
const REQUEST_ROWS = 1000;
export default {

    name: "pigeonRacingPlatform3D",
    // props: ['onlietestData', 'colseload', 'sendisHide', 'sendShowPigeons', 'sendDeviceImeiArray'],
    components: {
        GameListData, PigeonListData
    },

    watch: {
        // TODO 监听 数据 变化 加载实时数据
        // 
        // onlietestData(newVal, oldVal) {


        // if (newVal && newVal.length > 0) {
        //     console.log("数据:",newVal);
        //     // this.list.push(newVal.positions);
        //     this.modelAnimate(newVal,'./mapGLB/gezia.glb');

        //   } else {
        //     // 处理空数据或未定义的情况
        //     console.log("test轨迹数据为空或未定义");
        //     // this.drawPolyline(newVal);
        //     console.log("数据:",newVal.length);
        //   }

        // }

        // TODO 监听 数据 变化
        // sendDeviceImeiArray(newVal, oldVal) {
        //     // console.log(newVal.length,"监听 数据 变化:",newVal);
        //     this.hideShowDevice(newVal);

        // },
        // // 隐藏实体 移除实体
        // sendisHide(newVal, oldVal) {
        //     this.removeAllEntity();

        // },

        // // 显示实体
        // sendShowPigeons(newVal, oldVal) {
        //     this.showAllEntity();
        // }

    },
    data() {
        return {
            map2Dor3D: true,
            myWidth: window.innerWidth,
            myHeight: window.innerHeight,
            // 风场配置
            windOptions: {
                colorScale: [
                    'rgb(36,104, 180)',
                    'rgb(60,157, 194)',
                    'rgb(128,205,193 )',
                    'rgb(151,218,168 )',
                    'rgb(198,231,181)',
                    'rgb(238,247,217)',
                    'rgb(255,238,159)',
                    'rgb(252,217,125)',
                    'rgb(255,182,100)',
                    'rgb(252,150,75)',
                    'rgb(250,112,52)',
                    'rgb(245,64,32)',
                    'rgb(237,45,28)',
                    'rgb(220,24,32)',
                    'rgb(180,0,35)',
                ],
                frameRate: 16,
                maxAge: 60,
                globalAlpha: 0.9,
                velocityScale: 1 / 30,
                paths: 2000
            },
            pigeonFileName: [

                "./mapGLB/1.glb",
                "./mapGLB/2.glb",
                "./mapGLB/3.glb",
                "./mapGLB/4.glb",
                "./mapGLB/5.glb",
                "./mapGLB/6.glb",
                "./mapGLB/7.glb",
                "./mapGLB/8.glb",
            ], // 鸽子文件名称
            fileIndex: 0, // 当前索引

            WindyDataJson: WindyDataJson, // 起风数据
            isRadarMode: false, // 雷达模式
            isShowPopup: false, // 加载显示控件
            selectPigeonList: [], // 选中的鸽子列表
            pigeonRaceName: '',  // 鸽子赛名
            // 表单点击选中的行
            currentRow: null,
            currentRowKey: null, // 用于存储当前选中行的唯一标识
            searchVal: '', // 搜索框内容
            // 假设在类中定义这些属性 记录表格数据
            // 2d地图边框颜色
            borderColor2D: '#ffffffc4', // 初始边框颜色
            isBoxVisible: false, // 样式是否显示弹框
            isHovered: false,   // 样式是否显示弹框
            isEchelonVisible: false, // 样式是否显示弹框
            isShowIoc: false, // 样式是否显示弹框
            isCesium2DVisible: false, // 控制小地图可见性的数据属性
            // 是否显示全屏控件 
            isShowFullscreenButton: true, // 显示控件
            // 显示比赛数据列表
            isShowGameDataButton: true, // 显示控件

            // 鸽子数据
            isShowPigeonData: false, // 显示控件
            isFlage: false, // 是否显示赛事列表
            isShow: true, // 显示控件
            oneData: 0,  // 控制加载一次地图

            // 跟踪上一个时钟状态 是否正在播放
            wasPlaying: false,
            iswindy: false, // 显示控件
            iswindyVisible: false, // 显示控件 风场
            // 是否显示排名数据
            isShowRanking: true,
            isShowRankingHovered: true,
            isShowPigeonDataHovered: true,

            // 终点
            endText: this.$t('phoneLanguage.endPoint'),
            startText: this.$t('phoneLanguage.startPoint'),
            // 公里
            kilometerText: this.$t('phoneLanguage.kilometer'),

            EchelonTime: "", // 当前时间
            echelonData_0_20: 0, // 20公里以下  
            echelonData_20_35: 0, // 
            echelonData_35_50: 0, // 35公里以上
            echelonData_50_65: 0, // 50公里以上
            echelonData_65_80: 0, // 65公里以上
            echelonData_80: 0, // 80公里以上

            circleRadius_30: 20000.0, // 米作为单位，这里 红
            circleRadius_100: 35000.0, // 使用米作为单位，这里 蓝色 
            circleRadius_200: 50000.0, // 使用米作为单位，这里 绿色
            circleRadius_300: 65000.0, // 半径为使用米作为单位，这里 紫色
            circleRadius_400: 80000.0, // 半径，这里 黄

            circleCenter_30: 0,
            circleCenter_100: 0,
            circleCenter_200: 0,
            circleCenter_300: 0,
            circleCenter_400: 0,
            // tableData: TrajectoryData, // TODO 本地数据测试


            // TODO 按钮事件
            currentMapMode: true, // 初始模式设为3D
            isShowbaseLayerPicker: true, // 初始影像显示图层


            statisticsInterval: null, // 用于存储定时器的引用


            eventStartTime: 0, //   开始时间
            eventEndTime: 0,  // 结束时间
            eventName: '',  // 赛事名称
            currentIndex: -1, // 切换实体当前索引

            startLat: 0,  // 开始纬度
            startLng: 0,  // 开始经度
            endLat: 0,  // 结束纬度
            endLng: 0,  // 结束经度

            startTime: 0,  // 开始时间
            stopTime: 0,  // 结束时间
            viewer: null,
            mLatLng: [],
            //    id:this.list[index][0].deviceImei+"--->"+this.list[index][0].deviceName,
            // TrajectoryData   MyDataSource // TODO 本地数据测试
            // list: TrajectoryData,
            processedTableData: [], // 用于存储处理后排名的数据
            // 用于存储已生成的颜色
            generatedColors: new Set(),
            // 存储当前唯一的颜色
            uniqueColor: '',


        };
    },
    // TODO 要关闭的时候  不销毁地图 在关闭赛事时 做了处理
    beforeDestroy() {
        // 销毁地图
        if (window.viewer) {
            // 移除天气
            // removeStage(window.viewer);

            //  销毁鼠标移动事件
            // window.mouseMoveHandler.removeInputAction(debouncedMouseMove, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
            window.mouseMoveHandler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);

            // 移除监听器
            window.viewer.clock.onTick.removeEventListener(this.mOnTick);

            window.viewer.entities.removeAll(); // 移除所有实体
            window.viewer.destroy();
            window.viewer = null;

        }

        // 销毁地图 2D 地图
        if (window.viewer2D) {
            window.viewer2D.entities.removeAll(); // 移除所有实体
            window.viewer2D.destroy();
            window.viewer2D = null;

        }


        // 在组件销毁之前清理定时器
        if (this.statisticsInterval) {
            clearInterval(this.statisticsInterval);
            this.statisticsInterval = null;
        }




    },

    // destroyed() {
    //     if (window.viewer) {
    //         window.viewer.entities.removeAll(); // 移除所有实体
    //         window.viewer = null;
    //     }
    // },
    beforeCreate() {
        console.log("beforeCreate");
        // 移除其它缓存数据
        window.sessionStorage.removeItem('lang');
        window.sessionStorage.removeItem("raceId");
        window.sessionStorage.removeItem('historicalPigeonRaceName')
        window.sessionStorage.removeItem('historicalTrackStartTime')
        window.sessionStorage.removeItem('historicalTrackEndTime')
        // 移除时间搓
        window.sessionStorage.removeItem('historicalTrackStartTimeStamp')
        window.sessionStorage.removeItem('historicalTrackEndTimeStamp')

        window.sessionStorage.removeItem("historicalTrackStarLat");
        window.sessionStorage.removeItem("historicalTrackStarLon");

        window.sessionStorage.removeItem("historicalTrackEndLon");
        window.sessionStorage.removeItem("historicalTrackEndLat");

        window.sessionStorage.removeItem("pigeonTrajectoryData");

        window.sessionStorage.removeItem("pigeonNum");

        window.sessionStorage.removeItem("myDeviceImei");
        window.sessionStorage.removeItem("historicalTrackDeviceImei");
    },
    created() {
        console.log("created");


        // 获取当前系统语言
        const language = navigator.language || navigator.userLanguage;
        // 判断语言
        let systemLanguage;
        if (language.startsWith('zh')) {
            // 判断是简体中文还是繁体中文
            if (language === 'zh-CN' || language === 'zh-Hans' || language === 'zh') {
                systemLanguage = 'zh-CN';
            } else if (language === 'zh-TW' || language === 'zh-Hant') {
                systemLanguage = 'zh-TW';
            } else {
                systemLanguage = 'en-US';
            }
        } else if (language.startsWith('en')) {
            systemLanguage = 'en-US';
        } else {
            systemLanguage = 'en-US';
        }

        // console.log('系统语言是:', systemLanguage);

        // 打印当前系统语言
        // console.log("打印当前系统语言language", language);
        window.sessionStorage.setItem('lang', systemLanguage);

        // 从 sessionStorage 获取语言设置，如果没有则默认为 'zh-CN'  'en-US'    'zh-TW'
        const lang = window.sessionStorage.getItem('lang');
        console.log(" sessionStorage语言", lang);
        // 设置 Vue I18n 的当前语言
        this.$i18n.locale = lang;

        // 打印当前语言
        console.log("打印当前语言", this.$i18n.locale);





    },

    mounted() {
        console.log("mounted");



        // console.log("mounted",require('cesium'));
        // 初始化时生成一个唯一颜色
        this.generateUniqueColor();

        // cesium 地图初始化
        this.initCesium3D();
        this.initCesium2D();


    },
    activated() { // 激活时调用
        console.log("activated");
        // 如果点了风场 添加风场 没有作用
        // if (this.iswindyVisible) {

        //     // TODO 初始化 绘制风场
        //     window.windLayer = new CesiumWind.WindLayer(this.WindyDataJson, this.windOptions);

        //     window.windLayer.addTo(window.viewer);
        // }

    },
    deactivated() { // 销毁时调用
        console.log("deactivated");
        
        // 如果点了风场 先移除风场
        if (window.windLayer) {
            this.iswindyVisible = false;
            // 移除风场
            window.windLayer.remove();
        }
    },
    computed: {
        // var offset = new Date() + "";     //将时间格式转为字符串
        // console.log(offset);  //  Mon Nov 02 2020 20:57:20 GMT-0600 (北美中部标准时间)

        filteredData() {
            // 1排序
            // return this.processedTableData.slice().sort((a, b) => a.endDistance - b.endDistance);

            // 2 过滤数据，根据搜索值筛选
            // return this.processedTableData?.filter(data =>
            //     !this.searchVal ||
            //     data.deviceImei.toLowerCase().includes(this.searchVal.toLowerCase()) ||
            //     (data.index != null && data.index.toString().includes(this.searchVal))
            // );

            // 结合 首先，根据搜索值过滤数据
            const filtered = this.processedTableData?.filter(data =>
                !this.searchVal ||
                data.deviceImei.toLowerCase().includes(this.searchVal.toLowerCase()) ||
                (data.index != null && data.index.toString().includes(this.searchVal))
            );

            // 然后，对过滤后的数据进行排序
            return filtered?.slice().sort((a, b) => a.endDistance - b.endDistance);

        },
    },
    methods: {


        getCurrentCharacter() {
            // 根据 currentIndex 返回当前字符
            return this.pigeonFileName[this.fileIndex];
        },
        getPigeonFileName() { // 顺序设置鸽子模型
            // 获取当前字符
            const currentChar = this.getCurrentCharacter();

            // 更新 currentIndex 以指向下一个字符
            this.fileIndex = (this.fileIndex + 1) % this.pigeonFileName.length;

            // 返回当前字符（这一步其实是可选的，因为你已经在模板中绑定了 getCurrentCharacter 方法）
            return currentChar;
        },

        handleSelectionChange(val) {
            console.log("选这里：", val);
        },
        // TODO 点击表格数据
        handleCurrentChange(val) {

            // 检查val 是否有值
            if (!val) {
                return;
            }

            let imei = val.deviceImei;

            //记住选中的设备号
            window.sessionStorage.setItem('myDeviceImei', imei);

            console.log("点击了某一个", val, imei);
            // 切换实体

            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_' + imei);
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }

            // 更新当前索引
            this.currentIndex = (this.currentIndex + 1) % pointEntities.length;
            // 获取当前选中的实体
            var targetEntity = pointEntities[this.currentIndex];
            // TODO 给模型添加边框 不起作用 #e38e3c
            // targetEntity.silhouetteColor =targetEntity.myColor; 
            // targetEntity.silhouetteAlpha = 1;
            // targetEntity.silhouetteSize = 5;

            // 拿到设备号
            const deviceImei = (targetEntity.id).split('point_');
            // 显示该设备号的标记点
            // this.showEntityMarkerByDeviceImei(deviceImei[1]);
            // TODO 改变2D 小地图中的边框颜色
            console.log("切换模型---改变2D 小地图中的边框颜色", targetEntity.myColor);
            this.changeBorderColor(targetEntity.myColor);

            this.removeAllEntity2D();
            // 改变模型颜色
            this.setmodelColor(imei, targetEntity.myColor);


            // 先恢复线条大小
            this.resetPolylineWidth();
            // 设置2D 线条大小
            this.setPolylineWidth(imei, 5);

            // 把图片显示在最前面
            // this.setBillboardOrder(deviceImei[1]);

            //  console.log("切换模型---",(targetEntity.id).split('point_'));

            // 如果存在上一个实体，把标签 和 标记点 隐藏
            if (this.previousEntity && this.previousEntity.label) {
                this.previousEntity.label.show = false;
                this.previousEntity.label.fillColor = Cesium.Color.WHITE;
                // 隐藏鸽子
                // 是雷达模式才显示点
                if (this.isRadarMode) {
                    this.previousEntity.model.show = false;
                    this.previousEntity.point.show = true;
                    this.previousEntity.path.show = false;
                }



            }

            // 显示标签
            targetEntity.label.show = true;
            // 显示鸽子
            targetEntity.model.show = true;
            if (this.isRadarMode)
                targetEntity.path.show = true;
            // 不是是雷达模式才 隐藏点
            // if(!this.isRadarMode)
            targetEntity.point.show = false;
            // 给模型加个外边宽
            // outlineColor: Cesium.Color.BLUE,
            //     // outlineWidth: 3,

            // 
            // targetEntity.billboard.show = true;
            targetEntity.label.fillColor = Cesium.Color.RED;

            // 更新上一个实体的引用
            this.previousEntity = targetEntity;

            console.log("切换模型--->>>>>", targetEntity);
            // TODO 把选中的实体保存到session中
            // window.sessionStorage.setItem('myCurrentEntity',JSON.stringify(targetEntity));

            //  // 获取实体的 position 属性
            //  const positionProperty = targetEntity.position;
            //     // 从 Cesium 的时间控件中获取当前时间
            //     const currentJulianDate = Cesium.JulianDate.clone(viewer.clock.currentTime);
            //     // 获取当前时间的 position 值
            //     const targetCartesian = positionProperty.getValue(currentJulianDate);

            //     // // 设置相机飞到目标点的位置和距离（高度）
            // var cameraPosition = new Cesium.Cartesian3(targetCartesian.x, targetCartesian.y + 5000, targetCartesian.z);

            // // 使用 flyTo 方法飞到新的相机位置
            // window.viewer.camera.flyTo({
            //     destination: cameraPosition,
            //     orientation: {
            //         heading: Cesium.Math.toRadians(0.0), // 方向
            //         pitch: Cesium.Math.toRadians(-30.0), // 俯仰角（向下看）
            //         roll: 0.0
            //     },
            //     duration: 2.0 // 动画持续时间，单位为秒
            // });

            // 切换到目标实体 获取或设置相机当前正在跟踪的Entity实例。
            window.viewer.trackedEntity = targetEntity;


            // 获取当前镜头位置的笛卡尔坐标
            // let cameraPos = window.viewer.camera.position;

            // // 获取当前坐标系标准
            // let ellipsoid = window.viewer.scene.globe.ellipsoid;

            // // 根据坐标系标准，将笛卡尔坐标转换为地理坐标
            // let cartographic = ellipsoid.cartesianToCartographic(cameraPos);

            // // 获取镜头的高度
            // let height = cartographic.height;
            // 镜头远离
            // window.viewer.camera.zoomOut(height * 1.2);
            // window.viewer.camera.zoomOut( 60000);


            // var currentTime = window.viewer.clock.currentTime;
            // var targetPosition = targetEntity.position.getValue(currentTime)
            // console.log("切换模型---", possss);
            // // 使用flyTo方法平滑地缩放到目标实体，并设置视野大小
            // window.viewer.camera.flyTo({
            //     destination: targetEntity.position.getValue(currentTime), // 获取实体的当前位置
            //     duration: 2.0, // 动画持续时间，以秒为单位
            //     maximumHeight: 50000, // 可选：设置最大高度，防止摄像机飞得太高
            //     pitch: Cesium.Math.toRadians(-30), // 可选：设置摄像机的俯仰角，以弧度为单位（负值表示向下看）
            //     heading: Cesium.Math.toRadians(0), // 可选：设置摄像机的航向角，以弧度为单位
            //     roll: Cesium.Math.toRadians(0) // 可选：设置摄像机的横滚角，通常保持为0
            //     // 注意：没有直接的“缩放级别”参数，但可以通过设置destination和视野角度（如fovy）来间接控制
            //     // 如果需要控制视野大小，可以使用perspectiveFrustum并设置fovy（垂直视野角度）
            //     // 但是，这通常不是flyTo方法的直接用途，因为它更多地用于平滑过渡到新的位置和方向
            // });


            // 显示选中框
            window.viewer.selectedEntity = targetEntity;

            //             window.viewer.camera.setView({
            //     destination : targetPosition
            // });
            // var targetPosition = Cesium.Cartesian3.fromDegrees(longitude, latitude, height); // 目标位置的经纬度和高度
            // var orientation = {
            //     heading: Cesium.Math.toRadians(1), // 航向角
            //     pitch: Cesium.Math.toRadians(1),    // 俯仰角
            //     roll: 0.0                                      // 横滚角通常设置为0
            // };
            // window.viewer.camera.setView({
            //     destination: targetPosition,
            //     orientation: orientation
            // });

            // window.viewer.scene.screenSpaceCameraController.maximumZoomDistance =50000;

            // window.viewer.maxZoom = 8;
            // window.viewer.scene.camera.zoom = 10;

        },
        // 获取当前地图瓦片级别
        mapTileLevel(viewer) {
            let tiles = new Set();
            let tilesToRender = viewer.scene.globe._surface._tilesToRender;
            if (Cesium.defined(tilesToRender)) {
                for (let i = 0; i < tilesToRender.length; i++) {
                    tiles.add(tilesToRender[i].level);
                }
                console.log("当前地图瓦片级别为:");
                console.log(tiles);
            }
        },

        // TODO  这里99999
        getHandle(data) {
            // TODO 点击了进入 获取到子组件的数据 data

            // console.log("点击了进入 获取到子组件的数据 data", data);  true
            this.isShowPigeonData = data;
            this.isShowGameDataButton = !data;



            this.isFlage = true;

            // 开启风场
            this.iswindy = true;

            // 获取赛事名字
            // this.pigeonRaceName =   window.sessionStorage.getItem('historicalTrackDeviceImei') || ''

            // const pigeonNum = window.sessionStorage.getItem('pigeonNum') || 0;
            // console.log("点击了进入鸽子数量：",pigeonNum);
            // 如果鸽子数据量大于0 开始计算
            //    if (pigeonNum == 0) return;

            // 点了进入初始化地图 绘制起点终点 

            this.eventName = window.sessionStorage.getItem('historicalTrackDeviceImei') || '报错了'
            this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || '2023-11-08 08:30:00'
            this.eventEndTime = window.sessionStorage.getItem('historicalTrackEndTime') || '2023-11-08 11:30:00'

            this.startLat = window.sessionStorage.getItem('historicalTrackStarLat') || '24.37612331771184'
            this.startLng = window.sessionStorage.getItem('historicalTrackStarLon') || '120.75020175398568'
            this.endLng = window.sessionStorage.getItem('historicalTrackEndLon') || '120.42185330498783'
            this.endLat = window.sessionStorage.getItem('historicalTrackEndLat') || '23.358101743572295'

            // let startTime =    moment.tz(this.eventStartTime, 'Asia/Shanghai');
            // let endTime =  moment.tz(this.eventEndTime, 'Asia/Shanghai');

            // 假设 this.eventStartTime 和 this.eventEndTime 是有效的日期时间字符串
            let MstartTime = moment.tz(this.eventStartTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Shanghai');
            let MendTime = moment.tz(this.eventEndTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Shanghai');


            // console.log(MendTime,"赛事开始时间：", MstartTime);
            // window.alert(MendTime+"赛事开始时间 Shanghai ："+MstartTime);

            // console.log("赛事开始时间MstartTime.toDate()-->：", MstartTime.toDate());
            //  window.alert("赛事开始时间MstartTime.toDate()："+ MstartTime.toDate());

            this.startTime = Cesium.JulianDate.fromDate(MstartTime.toDate()); // 中国时间 开始时间
            this.stopTime = Cesium.JulianDate.fromDate(MendTime.toDate());  // 中国时间 结束时间 1730981637000



            // console.log("当前时间-->this.startTime", this.startTime.clone());
            // console.log("当前时间-->this.stopTime", this.stopTime.clone());
            // window.alert("赛事开始时间：" +  this.startTime.clone());
            // 初始化地图数据 绘制起点终点 
            this.initCesium3DData();
            // 显示2D 地图
            this.isCesium2DVisible = true;
            // 初始化2D 地图 显示位置
            this.jump2DMapPosition();

            // this.initCesiumMapData3D(data);
            // this.initCesiumMapData2D(data);



        },
        // TODO 获取到子组件传递过来的数据   选了哪些鸽子
        getigeonListHandle(data) {
            console.log("接收到子组件传递过来的数据选择了哪些鸽子", data);

            // // TODO 开始 转圈圈
            // this.isShowPopup = true;
            // // TODO 发送请求获取轨迹数据
            // this.startSearchTrackLog(data)

            this.selectPigeonList = data;
        },
        initPigeonRaceList(data) { // TODO 初始化赛事列表数据
            // 拿到鸽子定位数据
            if (data.length > 0) {
                // console.log("有数据初始化地图",data);
                // 把数据存到sission中 用于2D 地图展示数据
                window.sessionStorage.setItem('pigeonTrajectoryData', JSON.stringify(data));

                // 初始化3D地图数据 假数据
                // this.initCesiumMapData3D(this.list);
                // this.initCesiumMapData2D(this.list);
                // // 初始化计时器
                // this.initTimer(this.list);
                //    // TODO 获取假的数据
                //  this.processTableData(this.list);


                //3D
                console.log("有数据初始化地图map2Dor3D", this.map2Dor3D);
                // 在组件销毁之前清理定时器
                if (this.statisticsInterval) {
                    clearInterval(this.statisticsInterval);
                    this.statisticsInterval = null;
                }

                //  测试真实实时数据
                this.initCesiumMapData3D(data);
                this.initCesiumMapData2D(data);
                // 初始化计时器
                this.initTimer(data);


                // TODO 获取假的数据
                this.processTableData(data);

                if (this.map2Dor3D == 1 && !this.isRadarMode) {
                    this.map2Dor3D = 0;
                    // 改成跳转页面 2D 地图
                    this.$router.push('/pigeonRacingPlatform2D')
                }



            } else {
                console.log("没有数据初始化地图");
                // 清空2D 轨迹数据
                window.sessionStorage.setItem('pigeonTrajectoryData', []);
                // 表格数重置
                this.processedTableData = [];


                if (window.viewer) { // 3D
                    // 移除监听器
                    window.viewer.clock.onTick.removeEventListener(this.mOnTick);

                    // 在组件销毁之前清理定时器
                    if (this.statisticsInterval) {
                        clearInterval(this.statisticsInterval);
                        this.statisticsInterval = null;
                    }

                    // TODO 移除所有鸽子实体 排除地图上是否有这个实体了则不添加 删除存在的
                    // 3D 获取所有以'point_'开头的实体
                    var excludePointEntities = window.viewer.entities.values.filter(function (entity) {
                        return entity.id.startsWith('point_');
                    });

                    // 如果没有找到任何实体，则退出函数
                    if (excludePointEntities.length != 0) {

                        excludePointEntities.forEach(entity => {
                            window.viewer.entities.remove(entity);  // 删除实体
                            // entity.show = false;         // 隐藏所有实体
                        });
                    }


                }
                // 2D
                if (window.viewer2D) {
                    window.viewer2D.entities.removeAll(); // 移除所有实体
                }

            }


        },

        startSearchTrackLog(listData) { //TODO 根据设备号 查询轨迹数据
            // this.queryDeviceList = this.allDeviceList;
            if (listData.length) {
                const eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
                const eventEndTime = window.sessionStorage.getItem('historicalTrackEndTime') || ''

                // let startTime = moment(eventStartTime).format("yyyy-MM-DD HH:mm:ss")
                // let endTime = moment(eventEndTime).format("yyyy-MM-DD HH:mm:ss")

                let startTime = moment.tz(eventStartTime, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
                let endTime = moment.tz(eventEndTime, 'Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');


                // TODO 这里赛事没有数据
                //   let startTime =   moment(new Date() ).format("yyyy-MM-DD 00:00:00")
                // let endTime =   moment(eventEndTime ).format("yyyy-MM-DD 23:59:59")

                console.log('开始查询轨迹数据 这里赛事没有数据--->', startTime, endTime)
                let jsonArray = []

                for (let i = listData.length - 1; i >= 0; i--) {
                    // let deviceImei = listData[i]
                    // if (this.deviceMap.has(deviceImei) && '1' === this.deviceMap.get(deviceImei)) {
                    // let trackList = this.trackListMap.get(deviceImei)
                    let jsonObject = {}
                    jsonObject['deviceImei'] = listData[i].deviceImei
                    jsonObject['startTime'] = startTime
                    jsonObject['endTime'] = endTime
                    jsonObject['requestRows'] = REQUEST_ROWS
                    jsonObject['nextId'] = 0

                    jsonArray.push(jsonObject)

                }
                // console.log('拼装的请求数据jsonArray', jsonArray.length, jsonArray)
                // if (jsonArray.length) {
                //   this.onMultipleDeviceTraceLog(jsonArray)
                //   return
                // }
                // 发送请求
                this.onMultipleDeviceTraceLog(jsonArray);


            }
            else {
                // 没有选择数据
                console.log("没有数据初始化地图");
                // 如果是雷达模式
                if (this.isRadarMode) {
                    this.removeAllModel();
                } else {
                    this.showAllModel();
                }



                // 清空2D 轨迹数据
                window.sessionStorage.setItem('pigeonTrajectoryData', []);
                // 表格数重置
                this.processedTableData = [];


                if (window.viewer) { // 3D
                    // 移除监听器
                    window.viewer.clock.onTick.removeEventListener(this.mOnTick);

                    // 在组件销毁之前清理定时器
                    if (this.statisticsInterval) {
                        clearInterval(this.statisticsInterval);
                        this.statisticsInterval = null;
                    }

                    // TODO 移除所有鸽子实体 排除地图上是否有这个实体了则不添加 删除存在的
                    // 3D 获取所有以'point_'开头的实体
                    var excludePointEntities = window.viewer.entities.values.filter(function (entity) {
                        return entity.id.startsWith('point_');
                    });

                    // 如果没有找到任何实体，则退出函数
                    if (excludePointEntities.length != 0) {

                        excludePointEntities.forEach(entity => {
                            window.viewer.entities.remove(entity);  // 删除实体
                            // entity.show = false;         // 隐藏所有实体
                        });
                    }


                }
                // 2D
                if (window.viewer2D) {
                    window.viewer2D.entities.removeAll(); // 移除所有实体
                }
                // 关闭转圈圈
                this.isShowPopup = false;


            }

            // for(let [key, value] of this.trackListMap) {
            //   this.addDeviceTrackMarker(key, value)
            // }
            // this.trackListMap.clear()
        },
        // TODO 9999999999999999这里数据来源 等待方法执行完成后再执行
        async onMultipleDeviceTraceLog(jsonArray) {
            // 开始 结束 时间搓
            let startTimeStamp = window.sessionStorage.getItem('historicalTrackStartTimeStamp')
            let endTimeStamp = window.sessionStorage.getItem('historicalTrackEndTimeStamp')

            await getMultipleDeviceTraceLog(jsonArray).then(res => {
                if (res.data.code === 0) {
                    let dataList = res.data.data  // 每拿到数据 每条 数据 假设10个
                    // console.log('轨迹数据dataList', dataList.length)
                    if (dataList && dataList.length) {
                        let isHasTrack = false
                        // 循环 在从 list 中获取数据
                        // 先重治数据
                        // 没十条
                        var testArrayData = [];

                        dataList.forEach((element, rank) => {
                            // 每个条设备 list
                            // console.log('element轨迹数据', element.list)
                            // this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
                            // this.eventEndTime 

                            if (element && element.list && element.list.length) {
                                // console.log('element.list.length轨迹数据', element.list.length)
                                // console.log('element.list.length轨迹数据',this.eventStartTime,this.eventEndTime ,this.startLng,this.startLat, this.endLat,this.endLng,startTimeStamp, endTimeStamp);
                                isHasTrack = true

                                this.latLngDataTest = [];

                                // 添加第一条数据 
                                // 添加开始时间的自定义数据
                                // const startData = {
                                //     rank: 0, // 假设开始数据的排名为0，或者根据需要设置
                                //     logId: null, // 或者根据需要设置
                                //     deviceImei: element.list[0]?.deviceImei || 'default_imei', // 使用第一条数据的设备IMEI或默认值
                                //     deviceName: element.list[0]?.deviceName || 'default_name', // 使用第一条数据的设备名称或默认值
                                //     lng: startLatLng.lng,
                                //     lat: startLatLng.lat,
                                //     altitude: 1, // 或者根据需要设置
                                //     ringNo: element.list[0]?.ringNo || 0, // 使用第一条数据的鸽环号或默认值
                                //     gpsLocationTime: startTime,
                                //     speed: 0, // 或者根据需要设置
                                //     gpsTime: startTime,
                                //     deviceVol: element.list[0]?.deviceVol || 0, // 使用第一条数据的电量或默认值
                                // };
                                // this.latLngDataTest.push(startData);


                                // 循环 每个条数据
                                element.list.forEach((item, indx) => {
                                    //                     deviceImei 设备号
                                    // deviceName 昵称
                                    // ringNo  鸽环号

                                    // altitude  高度
                                    // gpsLocationTime 时间当期记录的时间
                                    // lng, 经度
                                    // lat, 纬度

                                    // 弹出层中额外：
                                    // speed  速度
                                    // gpsTime： 记录时间



                                    // deviceVol 电量 element.list.length > 1 && 

                                    if (indx == 0) {
                                        // 添加第一条数据
                                        // console.log('添加第一条数据',element.list.length );
                                        const startData = {

                                            // rank: 1, // 假设开始数据的排名为0，或者根据需要设置
                                            // logId: null, // 或者根据需要设置
                                            // deviceImei: element.list[0]?.deviceImei || 'default_imei', // 使用第一条数据的设备IMEI或默认值
                                            // deviceName: element.list[0]?.deviceName || 'default_name', // 使用第一条数据的设备名称或默认值
                                            // lng: this.startLng,
                                            // lat: this.startLat,
                                            // altitude: 1, // 或者根据需要设置
                                            // ringNo: element.list[0]?.ringNo || 0, // 使用第一条数据的鸽环号或默认值
                                            // gpsLocationTime: startTimeStamp,
                                            // speed: 0, // 或者根据需要设置
                                            // gpsTime: this.eventStartTime,
                                            // deviceVol: element.list[0]?.deviceVol || 0, // 使用第一条数据的电量或默认值

                                            rank: item?.rank ? item.rank : rank + 1,
                                            logId: item.logId,
                                            deviceImei: item.deviceImei,
                                            deviceName: item.deviceName,
                                            lng: (this.startLng - 0),
                                            lat: (this.startLat - 0),
                                            altitude: item?.altitude ? item.altitude : 0,
                                            ringNo: item?.ringNo || 0,
                                            gpsLocationTime: (startTimeStamp - 0),
                                            speed: item?.speed ? item.speed : 0,
                                            gpsTime: this.eventStartTime,
                                            deviceVol: item?.deviceVol ? item.deviceVol : 0,
                                            deviceVol: item?.deviceVol ? item.deviceVol : 0,
                                            accumulateDuration: 0, // 飞行时长
                                            accumulateOdometer: 0,// 飞行距离

                                        };
                                        this.latLngDataTest.push(startData);

                                    }
                                    // console.log('indx',indx ,element.list.length);

                                    // TODO 拼装数据 
                                    const lnglat = {
                                        // 排名
                                        rank: item?.rank ? item.rank : rank + 1,
                                        logId: item.logId,
                                        deviceImei: item.deviceImei,
                                        deviceName: item.deviceName,
                                        lng: item.lng, lat: item.lat,
                                        altitude: item?.altitude ? item.altitude : 0,
                                        ringNo: item?.ringNo || 0,
                                        gpsLocationTime: item.gpsLocationTime,
                                        // TODO 这里 需要优化 转换时间
                                        // gpsLocationTime: this.setStringTimeToTimeStamp(item.gpsTime),

                                        speed: item?.speed ? item.speed : 0,
                                        gpsTime: item.gpsTime,
                                        deviceVol: item?.deviceVol ? item.deviceVol : 0,
                                        deviceVol: item?.deviceVol ? item.deviceVol : 0,

                                        accumulateDuration: item?.accumulateDuration || 0, // 飞行时长
                                        accumulateOdometer: item?.accumulateOdometer || 0,// 飞行距离

                                    };

                                    // console.log('拼装后的每次10条？lnglat', lnglat)
                                    this.latLngDataTest.push(lnglat)


                                    // 最后一个点
                                    if (element.list.length == indx + 1) {
                                        // console.log('最后一个点',element.list.length );
                                        // console.log('最后一个点',indx );
                                        // console.log('最后一个点item',item );


                                        const startData = {
                                            rank: item?.rank ? item.rank : rank + 1,
                                            logId: item.logId,
                                            deviceImei: item.deviceImei,
                                            deviceName: item.deviceName,
                                            lng: item.lng, lat: item.lat,
                                            altitude: 0,
                                            ringNo: item?.ringNo || 0,
                                            gpsLocationTime: (endTimeStamp - 0),
                                            speed: item?.speed ? item.speed : 0,
                                            gpsTime: this.eventEndTime,
                                            deviceVol: item?.deviceVol ? item.deviceVol : 0,
                                            accumulateDuration: item?.accumulateDuration || 0, // 飞行时长
                                            accumulateOdometer: item?.accumulateOdometer || 0,// 飞行距离



                                        };
                                        this.latLngDataTest.push(startData);

                                    }



                                })



                                testArrayData.push(this.latLngDataTest)
                            }

                        })

                        // 子组件发送数据
                        //   this.$emit('onEventigeonList', testArrayData)
                    }


                    // console.log('拼装后的经纬度数据为:-home', this.latLngDataTest)
                    // console.log('拼装后的每次10条？', testArrayData)

                    // 拿到数据 初始化地图数据
                    this.initPigeonRaceList(testArrayData);

                    // 移除实体 重新生成地图数据
                    if (this.isRadarMode) {
                        // 雷达模式
                        this.removeAllModel();

                    } else {
                        this.showAllModel();
                    }


                }


                // this.queryDeviceList.splice(0, this.queryDeviceList.length)
                // this.startSearchTrackLog()
                // 关闭转圈圈
                this.isShowPopup = false;

            }).catch(e => {
                console.log('轨迹数据查询失败', e)
                // this.queryDeviceList.splice(0, this.queryDeviceList.length)
                // this.startSearchTrackLog()
                // 关闭转圈圈
                this.isShowPopup = false;
                //  提示失败
                this.$message.error('轨迹数据查询失败')

            })
        },
        // TODO 工具类 时间格式转 时间戳 字符串 2019-03-08 15:47:00 转换为时间戳
        setStringTimeToTimeStamp(dateString) {
            const date = new Date(dateString);
            const timestamp = date.getTime(); // 获取时间戳（毫秒）
            return timestamp;
        },
        // TODO 工具类 时间格式转 时间戳  00:00:00
        formatMillisecondsToTime(ms) {
            const totalSeconds = Math.floor(ms / 1000);
            const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, '0');
            const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(2, '0');
            const seconds = String(totalSeconds % 60).padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        },

        // TODO 点击了返回 显示赛事列表，隐藏鸽子数据
        closePigeonData() {
            this.map2Dor3D = 0;
            this.isShowPigeonData = false;
            this.isShowGameDataButton = true;
            this.isShowPigeonData = false;
            this.isFlage = false;
            this.isCesium2DVisible = false; // 关闭2D地图
            this.iswindy = false; // 关闭风场模式
            if (window.windLayer) {
                this.iswindyVisible = false;
                window.windLayer.remove();
            }

            this.oneData = 0;
            this.selectPigeonList = []; // 清空选择表格中的的数据

            // 移除其它缓存数据
            window.sessionStorage.removeItem('myDeviceImei')

            window.sessionStorage.removeItem("raceId");
            window.sessionStorage.removeItem('historicalPigeonRaceName')
            window.sessionStorage.removeItem('historicalTrackStartTime')
            window.sessionStorage.removeItem('historicalTrackEndTime')
            window.sessionStorage.removeItem("historicalTrackStarLat");
            window.sessionStorage.removeItem("historicalTrackStarLon");

            window.sessionStorage.removeItem("historicalTrackEndLon");
            window.sessionStorage.removeItem("historicalTrackEndLat");

            // 移除时间搓
            window.sessionStorage.removeItem('historicalTrackStartTimeStamp')
            window.sessionStorage.removeItem('historicalTrackEndTimeStamp')

            // 销毁赛鸽数量
            window.sessionStorage.removeItem('pigeonNum')
            // 清空2D 轨迹数据
            window.sessionStorage.setItem('pigeonTrajectoryData', []);
            // TODO 销毁地图
            if (window.viewer) {
                // 移除监听器
                window.viewer.clock.onTick.removeEventListener(this.mOnTick);

                window.viewer.entities.removeAll(); // 移除所有实体
                window.viewer.destroy();
                window.viewer = null;

            }

            // 销毁地图 2D 地图
            if (window.viewer2D) {
                window.viewer2D.entities.removeAll(); // 移除所有实体
                window.viewer2D.destroy();
                window.viewer2D = null;

            }


            // 在组件销毁之前清理定时器
            if (this.statisticsInterval) {
                clearInterval(this.statisticsInterval);
                this.statisticsInterval = null;
            }
            // 消除假数据
            this.processedTableData = [];
            // cesium 地图初始化 
            this.initCesium3D();
            this.initCesium2D();


        },
        // TODO 雷达模式
        showRadarMode() {
            this.isRadarMode = true;
            this.isShowPopup = true;
            // TODO 发送请求获取轨迹数据
            this.startSearchTrackLog(this.selectPigeonList)

            // // 移除实体 重新生成地图数据
            // this.removeAllModel();
            // 隐藏 小地图
            this.isCesium2DVisible = false;
            this.isShow = false;


        },
        // TODO 轨迹模式 Trajectory mode
        showTrajectoryMode() {



            // this.selectPigeonList = data;
            this.isRadarMode = false;
            this.isShowPopup = true;
            // TODO 发送请求获取轨迹数据
            this.startSearchTrackLog(this.selectPigeonList)



            // 显示模型鸽子 隐藏点
            // this.showAllModel();
            // 显示小地图
            this.isCesium2DVisible = true;
            this.isShow = false;


        },
        toggleMapMode() {
            // 改成跳转页面
            this.$router.push('/pigeonRacingPlatform2D')

            // TODO 切换地图模式 true 3 false 2 d
            // this.currentMapMode = !this.currentMapMode ;
            // if(this.currentMapMode){
            //     // this.initMapView();
            //     // window.viewer.scene.morphTo3D();
            //      // 在Viewer实例创建后，立即将场景模式设置为2D 
            //     window.viewer.scene.mode = Cesium.SceneMode.SCENE3D;
            // // 这里可以添加额外的逻辑来处理地图模式的切换，
            // // 比如调用API来改变地图的显示等。

            // }else{
            //     window.viewer.scene.mode = Cesium.SceneMode.SCENE2D;
            //     // this.initMapView();
            //     // window.viewer.scene.morphTo2D();

            // }


        },
        ShowbaseLayerPicker() {
            this.isShowbaseLayerPicker = !this.isShowbaseLayerPicker;
        },
        // TODO 隐藏显示 2D 小地图
        showMarkerMapMode() {

            this.isCesium2DVisible = !this.isCesium2DVisible;
            // 测试销毁天气
            // removeStage(window.viewer);

            // console.log("cesium 3d",window.viewer.scene.mode);
            // 校验cesium 地图是不是3d的
            // if (window.viewer.scene.mode === Cesium.SceneMode.SCENE3D) {
            //     console.log("cesium 3d");
            // } else {
            //     console.log("cesium 2d");
            // }

        },
        // TODO 是否全屏显示
        isFullScreen() {
            this.isShowFullscreenButton = !this.isShowFullscreenButton;
            console.log("isFullScreen", Cesium.Fullscreen.fullscreen);
            if (Cesium.Fullscreen.fullscreen) { // true 退出全屏 false 全屏
                Cesium.Fullscreen.exitFullscreen()
                // Cesium.Fullscreen.requestFullscreen(window.viewer.scene.canvas)

            } else {
                Cesium.Fullscreen.requestFullscreen(document.body)
                // Cesium.FullScreen.destroy();
                // Cesium.Fullscreen.exitFullscreen ()
            }
        },
        // TODO 是否显示比赛数据
        isShowGameData() {
            if (this.isFlage) {
                // 显示鸽子列表数据
                this.isShowPigeonData = true;
                this.isShow = true;
            } else {
                this.isShowGameDataButton = !this.isShowGameDataButton;
            }

        },
        // TODO 初始化地图视野
        initMapView() {
            console.log("initMapView", this.startLng, this.startLat,);
            if (window.viewer.scene.mode === Cesium.SceneMode.SCENE3D) {
                console.log("初始化地图视野cesium 3d");
            } else {
                console.log("初始化地图视野cesium 2d");
                // window.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 5000000;




            }

            // // 设置视野
            // window.viewer.camera.flyTo({
            //             destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 500000.0),
            //             duration: 1, // 以秒为单位的飞行持续时间。
            //             offset: {
            //                 heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
            //                 pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
            //                 range: 10								// 到中心的距离，以米为单位。								
            //             }
            //         }); 


        },

        // TODO 样式
        showBox() {
            this.isBoxVisible = true;
            this.isShowIoc = true;
            // 可选：调整botBox的位置以正好显示在“数据”上方
            this.$nextTick(() => {
                const botBox = this.$refs.botBox;
                // 这里可以根据需要添加逻辑来调整botBox的样式，比如top和left属性
                // 注意：由于botBox使用了绝对定位，你可能需要为其父元素设置相对定位（position: relative;）
            });
        },
        hideBox() {
            this.isBoxVisible = false;
            this.isShowIoc = false;
        },
        showEchelonPopup() {
            // 判断赛鸽排名窗口是否展示 展示就要关闭
            if (this.isShowRanking) {
                this.isShowRanking = false;
            }
            // 显示阶梯数据
            this.isEchelonVisible = true;
            // console.log("echelonPopup")
        },
        // 显示赛鸽排名
        showPigeonRankingPopup() {
            // 判断阶梯数据窗口是否展示 展示就要关闭
            if (this.isEchelonVisible) {
                this.isEchelonVisible = false;
            }
            // 显示赛鸽排名(
            this.isShowRanking = !this.isShowRanking;;
        },
        // TODO 风场开启关闭
        showWindy() {


            this.iswindyVisible = !this.iswindyVisible;
            console.log("showWindy", this.iswindyVisible)
            if (this.iswindyVisible) {
                // // 开启风场
                // window.windy = new Windy(this.WindyDataJson, window.viewer);
                // window.timer = setInterval(function () {
                //     window.windy.animate();
                // }, 300);



                // TODO 初始化 绘制风场
                window.windLayer = new CesiumWind.WindLayer(this.WindyDataJson, this.windOptions);


                window.windLayer.addTo(window.viewer);


            } else {
                // 关闭风场
                // 在组件销毁之前清理定时器
                // if (window.timer) {
                //     clearInterval(window.timer);
                //     window.timer = null;


                // }
                // window.windy.removeLines();

                window.windLayer.remove();
            }
        },

        // 关闭阶梯数据
        closeEchelon() {
            this.isEchelonVisible = false;
        },
        // TODO 关闭赛鸽排名
        closeRanking() {
            this.isShowRanking = false;
        },
        // TODO 关闭显示赛事列表
        closeGameDataList() {
            this.isShowGameDataButton = false;

        },
        // TODO 关闭鸽子列表数据
        closePigeonDataList() {

            this.isShow = false;

        },
        //  结束样式

        // TODO 获取假数据方法
        processTableData(listData) {
            // 
            let idCounter = 1; // 初始化ID计数器

            // console.log("distance", this.startLng, this.startLat)

            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);

            // 计算累积时间 结束时间 - 开始时间

            // // TODO 计算 起点和终点的距离
            // // 计算距离 保留2位小数
            let distance = (Cesium.Cartesian3.distance(startPosition, endPosition) / 1000).toFixed(2);
            // console.log("distance",distance)
            // let endDistance = 10;
            this.processedTableData = listData?.map(array => {
                if (array.length > 0) {
                    // console.log("开始时间", array[0].gpsLocationTime);
                    // console.log("结束时间 ", array[array.length-1].gpsLocationTime);

                    // 计算时间飞行时长
                    let totalSeconds = this.formatMillisecondsToTime(array[array.length - 1].gpsLocationTime - array[0].gpsLocationTime);
                    // console.log("time",totalSeconds);
                    // formatMillisecondsToTime
                    // let hours = Math.floor(totalSeconds / 3600);
                    //         let minutes = Math.floor((totalSeconds % 3600) / 60);
                    //         let seconds = Math.floor(totalSeconds % 60);
                    //         let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                    //         console.log('formattedTime将累积时间转换为:', formattedTime);


                    return {
                        index: idCounter++,
                        deviceImei: array[0].deviceImei,
                        deviceName: array[0].deviceName,
                        endDistance: distance,
                        gpsLocationTime: totalSeconds,
                        stayTime: 0, // 停留时间
                        speed: 0,
                        height: 0,
                    };
                }
                return null; // 如果数组为空，返回 null 或处理为空对象等
            }).filter(item => item !== null); // 过滤掉 null 值
        },
        // TODO 手动触发选择第一行数据
        // selectFirstRow() {
        //     let imei =  window.sessionStorage.getItem('myDeviceImei') || '';
        //     // 确保有数据可供选择
        //     if (this.filteredData.length > 0) {
        //         // 由于无法直接触发 el-table 的行点击事件，
        //         // 我们直接调用 handleCurrentChange 方法并传入第一条数据
        //         // console.log("filteredData", this.filteredData);
        //         // console.log("selectFirstRow", imei);

        //         // window.sessionStorage.setItem('myDeviceImei', imei);
        //         // const firstRow = this.filteredData[0];
        //         if(imei){
        //                     // 查找选中的数据
        //             const firstRow = this.filteredData.find(item => item.deviceImei === imei);
        //             this.$nextTick(() => {
        //                 this.handleCurrentChange(firstRow);
        //                 // 手动触发行点击事件
        //                 // this.$refs.table.setCurrentRow(firstRow);
        //             });
        //         }else{
        //              // 查找选中的数据
        //         const firstRow = this.filteredData[0];
        //         this.$nextTick(() => {
        //             this.handleCurrentChange(firstRow);
        //             // 手动触发行点击事件
        //             // this.$refs.table.setCurrentRow(firstRow);
        //         });
        //         }

        //     }
        // },
        // 表示点击了第一个的行，触发当前行的改变事件
        selectFirstRow() {
            let imei = window.sessionStorage.getItem('myDeviceImei') || '';
            // 确保有数据可供选择
            if (this.filteredData.length > 0) {
                // 根据imei查找数据，如果不存在imei则选择第一条数据
                const firstRow = imei ? this.filteredData.find(item => item.deviceImei === imei) || this.filteredData[0] : this.filteredData[0];
                // // 设置sessionStorage中的imei（如果需要）
                // if (imei !== firstRow.deviceImei) {
                //     window.sessionStorage.setItem('myDeviceImei', firstRow.deviceImei);
                // }
                this.$nextTick(() => {
                    // 手动触发行点击事件或者调用处理方法（根据实际需求选择一种）
                    this.handleCurrentChange(firstRow);
                    // 如果需要，也可以取消下面这行的注释来替代上面的handleCurrentChange调用
                    // this.$refs.table.setCurrentRow(firstRow);
                });
            }
        },
        // TODO 11监听时钟变化
        mOnTick(clock) {


            // 同部2D 小地图时间
            this.synchronizeClocks();

            // 检查时钟是否正在播放
            var isPlaying = clock.shouldAnimate;
            //    console.log("mOnTick", isPlaying);

            // 如果从暂停变为播放，触发事件
            if (!this.wasPlaying && isPlaying) {

                this.selectFirstRow();
            }

            // 更新上一个状态
            this.wasPlaying = isPlaying;


            // 如果数雷大模式 才改变圆点颜色
            if (this.isRadarMode) {
                const _this = this;
                var currentTime = clock.currentTime;
                var entities = window.viewer.entities.values;
                this.EchelonTime = this.CesiumDateTimeXFormatter(currentTime);
                // //    console.log("this.EchelonTime",this.EchelonTime)
                entities.forEach(function (entity) {
                    // 只处理以'point_'开头的实体，假设这些是您想要监控的点
                    if (entity.id.startsWith('point_')) {

                        var position = entity.position.getValue(currentTime);
                        // 校验  position
                        if (position) {

                            if (Cesium.Cartesian3.distance(position, _this.circleCenter_400) <= _this.circleRadius_400
                                && Cesium.Cartesian3.distance(position, _this.circleCenter_300) > _this.circleRadius_300) {
                                // 实时监控每只鸽子

                                entity.point.color = Cesium.Color.YELLOW;
                            } else if (Cesium.Cartesian3.distance(position, _this.circleCenter_300) <= _this.circleRadius_300
                                && Cesium.Cartesian3.distance(position, _this.circleCenter_200) > _this.circleRadius_200) {

                                entity.point.color = Cesium.Color.PURPLE;
                            } else if (Cesium.Cartesian3.distance(position, _this.circleCenter_200) <= _this.circleRadius_200
                                && Cesium.Cartesian3.distance(position, _this.circleCenter_100) > _this.circleRadius_100) {

                                entity.point.color = Cesium.Color.GREEN;
                            } else if (Cesium.Cartesian3.distance(position, _this.circleCenter_100) <= _this.circleRadius_100
                                && Cesium.Cartesian3.distance(position, _this.circleCenter_30) > _this.circleRadius_30) {

                                entity.point.color = Cesium.Color.BLUE;

                            } else if (Cesium.Cartesian3.distance(position, _this.circleCenter_30) <= _this.circleRadius_30) {

                                entity.point.color = Cesium.Color.RED;

                            } else {
                                entity.point.color = Cesium.Color.WHITE; // 或者其他默认颜色

                            }

                        }

                    }
                });
            }






        },

        // 绘制轨迹线
        drawPolyline(coordinates) {
            // 遍历数据 循环添加轨迹线
            for (let i = 0; i < coordinates.length; i++) {
                const positions = []; // 创建一个空数组来存储Cartesian3对象
                for (let j = 0; j < coordinates[i].length; j++) {
                    // 将每个经纬度对转换为Cartesian3对象，并添加到positions数组中
                    // const [lon, lat] = coordinates[i][j];
                    positions.push(Cesium.Cartesian3.fromDegrees(coordinates[i][j].lng, coordinates[i][j].lat));
                }
                // 使用positions数组创建polyline实体，并添加到viewer中
                window.viewer.entities.add({
                    polyline: {
                        positions: positions, // 使用转换后的Cartesian3对象数组
                        width: 5,
                        material: Cesium.Color.fromCssColorString(this.getRandomColor()),
                    }
                });
            }

            //           // 绘制线
            // var dataSource = new Cesium.CustomDataSource("linedata");
            //             window.viewer.dataSources.add(dataSource);
            //             dataSource.entities.add({
            //                 polyline: {
            //                     name: "line",

            //                     positions: positions,
            //                     width: 5,
            //                     material: Cesium.Color.fromCssColorString("#00ff00"),

            //                 }
            //             });

            //     window.viewer.zoomTo(dataSource)
        },

        // TODO 初始化计时器
        initTimer(listDate) {
            // TODO 创建计时器
            // 确保 Cesium Viewer 已经初始化
            if (window.viewer) {
                // 初始化一个对象来存储每个实体的最后位置和时间
                this.lastPositions = {};
                this.lastTimes = {};
                this.stayTimes = {}; // 新增：用于存储每个实体的停留时间（秒）
                // 初始化累积时间和位置/时间记录
                this.cumulativeTimes = {}; // 用于存储每个实体的累积时间（秒）
                // 设置一个每分钟触发一次的定时器           
                this.statisticsInterval = setInterval(() => {
                    var currentTime = window.viewer.clock.currentTime;
                    // var currentTime = Cesium.JulianDate.clone(window.viewer.clock.currentTime);

                    // console.log("设置一个每分钟触发一次的定时器当前时间：", currentTime);
                    // 获取 entities 集合（注意：values 是一个迭代器，你可能需要将其转换为数组或使用其他方法遍历）
                    // TODO 获取所有显示的 entities
                    // const entities = window.viewer.entities.values;
                    // 获取所有以'point_'开头的实体 和显示的实体
                    var pointEntities = window.viewer.entities.values.filter(function (entity) {
                        return entity.id.startsWith('point_') && entity.show;
                    });

                    // 如果没有找到任何实体，则退出函数
                    if (pointEntities.length === 0) {
                        // console.log("没有找到以'point_'开头的实体");
                        return;
                    }

                    // TODO 鸽子排名
                    // 计算鸽子 和 终点的距离

                    let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);



                    // 重置圈内实体数量统计
                    // Object.values(circleCounts).forEach(count => count = 0);
                    // 重置排名数据
                    this.processTableData(listDate);

                    // 初始化 lastPosition 和 lastTime

                    pointEntities.forEach(entity => {
                        var entityId = entity.id;
                        // console.log('entityId:', entityId);

                        // 当期时间每个实体的位置
                        var position = entity.position.getValue(currentTime);

                        if (!position) return;

                        // TODO 表格数据 计算当前位置与终点的距离
                        let distance = (Cesium.Cartesian3.distance(position, endPosition) / 1000);
                        // // 根据 deviceImei 找到 tableData 中的对应对象并更新 endDistance
                        // let matchingData = _this.processedTableData.find(data => data.deviceImei === entity.id.replace('point_', '')); // 假设 id 是 'point_' + deviceImei
                        // if (matchingData) {
                        //     matchingData.endDistance = distance.toFixed(2); // 保留两位小数
                        // }
                        // 高度
                        var cartographic = Cesium.Cartographic.fromCartesian(position);
                        // console.log('position:', position);
                        // console.log('cartographic:', cartographic);
                        // var height = Cesium.Ellipsoid.WGS84.geodeticSurfaceNormal(cartographic, new Cesium.Cartesian3()); // 这实际上是椭球体的法线向量的 z 分量，但可以直接用作高度（从椭球体表面）的负值
                        // console.log('Altitude:', height);
                        //var altitude = -height; // 高度应为正值，所以取负
                        // console.log('Altitude:', altitude);

                        // 计算速度
                        // 初始化或更新实体的最后位置和时间
                        //   if (!(entityId in (this.lastPositions ?? {}))) {
                        if (!(entityId in this.lastPositions)) {
                            this.lastPositions[entityId] = position;
                            this.lastTimes[entityId] = currentTime;
                            this.stayTimes[entityId] = 0; // 初始化停留时间为0秒
                            this.cumulativeTimes[entityId] = 0; // 初始化累积时间为0秒
                        } else {
                            let lastPosition = this.lastPositions[entityId];
                            let lastTime = this.lastTimes[entityId];
                            let mydistance = Cesium.Cartesian3.distance(position, lastPosition); // 移动距离
                            var timeDifference = Cesium.JulianDate.secondsDifference(currentTime, lastTime);
                            let speed = timeDifference === 0 ? 0 : (mydistance / timeDifference) * 60; // 米/分钟
                            // console.log('mydistance:', mydistance);


                            // 更新累积时间
                            this.cumulativeTimes[entityId] += timeDifference;
                            // 将累积时间转换为 HH:mm:ss 格式
                            let totalSeconds = this.cumulativeTimes[entityId];
                            var formattedTime = "00:00:00"
                            // 为负数
                            if (totalSeconds >= 0) {

                                let hours = Math.floor(totalSeconds / 3600);
                                let minutes = Math.floor((totalSeconds % 3600) / 60);
                                let seconds = Math.floor(totalSeconds % 60);
                                formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                // console.log('formattedTime将累积时间转换为:', formattedTime);
                            }

                            // 检查是否停留（移动距离小于500米）
                            if (mydistance < 20) {
                                this.stayTimes[entityId] += timeDifference; // 增加停留时间
                            }
                            else {
                                this.stayTimes[entityId] = 0; // 重置停留时间
                            }

                            var stayTimeTime = "00:00:00"
                            // 为负数
                            if (this.stayTimes[entityId] >= 0) {
                                let totalSeconds = this.stayTimes[entityId];
                                let hours = Math.floor(totalSeconds / 3600);
                                let minutes = Math.floor((totalSeconds % 3600) / 60);
                                let seconds = Math.floor(totalSeconds % 60);
                                stayTimeTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                // console.log('formattedTime将累积时间转换为:', formattedTime);
                            }


                            // 将IMEI从entity.id中提取出来
                            let imei = entity.id.replace('point_', '');
                            // 查找匹配的数据对象（假设processedTableData是可变的）
                            let matchingDataIndex = this.processedTableData.findIndex(data => data.deviceImei === imei);
                            if (matchingDataIndex !== -1) {
                                // 更新匹配对象的endDistance（保留两位小数并转换回数字）
                                this.processedTableData[matchingDataIndex].endDistance = distance.toFixed(2);
                                // 高度
                                this.processedTableData[matchingDataIndex].height = cartographic.height.toFixed(2);
                                //速度
                                this.processedTableData[matchingDataIndex].speed = speed.toFixed(2);
                                // 累积时间
                                this.processedTableData[matchingDataIndex].gpsLocationTime = formattedTime; // 更新累积时间

                                //停留时间
                                this.processedTableData[matchingDataIndex].stayTime = stayTimeTime; // 更新停留时间

                                // 如果processedTableData是Vue组件的data属性，并且您希望Vue能够响应这个变化，
                                // 并且您没有使用Vue.set（因为我们在直接索引上修改），则可能需要通知Vue这个变化，
                                // 但通常直接修改数组元素应该足以触发更新（取决于Vue版本和配置）。
                                // 如果不更新，可以考虑使用Vue.set或替换整个数组。
                            } else {
                                // 可选：如果找不到匹配项，可以记录错误或执行其他逻辑
                                console.error(`No matching data found for IMEI: ${imei}`);
                            }

                        }




                        // 更新最后位置和时间
                        // 更新实体的最后位置和时间
                        this.lastPositions[entityId] = position;
                        this.lastTimes[entityId] = currentTime;




                    });

                }, 500); // TODO 60000 毫秒 = 1 分钟 = 1 分钟 半秒
            } else {
                console.error('Cesium Viewer is not initialized!');
            }

            // // TODO 获取假的数据
            // this.processTableData();
        },
        // TODO 初始化地图3D没有赛鸽数据
        initCesium3D() {

            // 先把 地图删掉
            if (window.viewer) {
                window.viewer.entities.removeAll(); // 移除所有实体
                window.viewer.destroy();
                window.viewer = null;
            }

            (Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg");
            // 备份自己的  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg
            // 詹总  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4YWFiNmJiMS1jNjRjLTRkM2QtYTgzOC05MTMwZDJjOGJmNzIiLCJpZCI6MjU2MDE3LCJpYXQiOjE3MzIwMTgzMDl9.N5fr5-Z7OGnVoirhJ-wA3NzQ4TFDidiw9cUzlQBZGKc
            //      eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkYzUwYjE0NS04ZTQ0LTRkMWMtYTI4Yi1iOWFhNDMzNDY3ZTAiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzE4OTg0OTR9.mj5LfUew2Hw2PyB9Fctyv4_KzPXv4GnyvHDlTqaNODQ
            window.viewer = new Cesium.Viewer("cesiumContainer", {
                // 优化

                // requestRenderMode : true, // 开启渲染模式
                // maximumRenderTimeChange : Infinity, // 最大渲染时间


                homeButton: true,
                sceneModePicker: false,  // 地图模式
                baseLayerPicker: false, // 影像切换
                animation: false, // 是否显示动画控件
                infoBox: false, // 是否显示点击要素之后显示的信息
                selectionIndicator: false, // 要素选中框
                geocoder: false, // 是否显示地名查找控件
                timeline: false, // 是否显示时间线控件
                fullscreenButton: false, // 是否显示全屏控件
                shouldAnimate: false,   // 默认不播放动画
                navigationHelpButton: false, // 是否显示帮助信息控件
                creditContainer: document.createElement("div"),

                //    useBrowserRecommendedResolution: false, // 是否选择浏览器推荐分辨率
                // scene3DOnly: true, // 仅显示三维场景
                showRenderLoopErrors: false, // 是否显示渲染错误



                // 地形 ok
                // terrain: Cesium.Terrain.fromWorldTerrain(), // 地形数据
                //   地形 ok
                terrain: Cesium.Terrain.fromWorldTerrain({
                    requestWaterMask: false, // 水面蒙版
                    requestVertexNormals: true  //
                }),

                // 影像 imageryProvider
                // imageryProvider: new Cesium.SingleTileImageryProvider({
                //     url: require("../../assets/images/map/1.jpg"),
                // }),



            });


            // 显示地图阴影
            // window.viewer.shadows= true;
            // window.viewer.scene.globe.enableLighting = true;
            // 显示帧率组件
            window.viewer.scene.debugShowFramesPerSecond = true;
            // 解决低性能设备黑屏
            window.viewer.scene.globe.showGroundAtmosphere = false
            //  TODO 优化性能
            // window.viewer.scene.requestRenderMode = true;   // 开启渲染模式 加速
            // window.viewer.scene.debugShowFramesPerSecond = true;
            // window.viewer.resolutionScale = 0.9; // 默认值为 1.0；

            // window.viewer.scene.msaaSamples = 4 // 抗锯齿
            // window.viewer.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿
            // window.viewer.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
            // // viewer.resolutionScale = window.devicePixelRatio; // 分辨率

            // Hides the stars
            // window.viewer.scene.skyBox.show = false;
            // Explicitly render a new frame
            // window.viewer.scene.requestRender(); // 渲染

            // 绘制起点终点
            // TODO 绘制贴地线   lng: 116.405419,
            // lat: 39.918034,   lng: 118.497402,
            // lat: 38.344641,
            // startPosition :0,
            // endPosition :0,





        },
        getHeading(pointA, pointB) {
            //建立以点A为原点，X轴为east,Y轴为north,Z轴朝上的坐标系
            const transform = Cesium.Transforms.eastNorthUpToFixedFrame(pointA);
            //向量AB
            const positionvector = Cesium.Cartesian3.subtract(pointB, pointA, new Cesium.Cartesian3());
            //因transform是将A为原点的eastNorthUp坐标系中的点转换到世界坐标系的矩阵
            //AB为世界坐标中的向量
            //因此将AB向量转换为A原点坐标系中的向量，需乘以transform的逆矩阵。
            const vector = Cesium.Matrix4.multiplyByPointAsVector(Cesium.Matrix4.inverse(transform, new Cesium.Matrix4()), positionvector, new Cesium.Cartesian3());
            //归一化
            const direction = Cesium.Cartesian3.normalize(vector, new Cesium.Cartesian3());
            //heading
            const heading = Math.atan2(direction.y, direction.x) - Cesium.Math.PI_OVER_TWO;
            return Cesium.Math.TWO_PI - Cesium.Math.zeroToTwoPi(heading);
        },
        flyToStartPoint() {
            // 设置视野
            //这是让你的画面以一个怎样的形式出现，相当于出场动画
            window.viewer.camera.flyTo({

                destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 2300),
                // orientation: {
                //     // 指向 z 180
                //     // heading:Cesium.Math.toRadians(-10),
                //     // 视角 y
                //     pitch:Cesium.Math.toRadians(-90),
                //     // 视角 x
                //     roll: -3.0,
                // },
                duration: 3,	// 以秒为单位的飞行持续时间。
                offset: {
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。								
                }
            });
        },


        // TODO 初始化地图3D有赛鸽数据
        initCesium3DData() {
            // 先把 地图删掉
            if (window.viewer) {
                window.viewer.entities.removeAll(); // 移除所有实体
                window.viewer.destroy();
                window.viewer = null;
            }

            (Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg");
            // 备份自己的  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg
            // 詹总  eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4YWFiNmJiMS1jNjRjLTRkM2QtYTgzOC05MTMwZDJjOGJmNzIiLCJpZCI6MjU2MDE3LCJpYXQiOjE3MzIwMTgzMDl9.N5fr5-Z7OGnVoirhJ-wA3NzQ4TFDidiw9cUzlQBZGKc
            //      eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkYzUwYjE0NS04ZTQ0LTRkMWMtYTI4Yi1iOWFhNDMzNDY3ZTAiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzE4OTg0OTR9.mj5LfUew2Hw2PyB9Fctyv4_KzPXv4GnyvHDlTqaNODQ

            // 从缓存中获取语言 如果 当期系统语言是英文 则不添加高德地图标注
            const lang = window.sessionStorage.getItem('lang');

            if (lang !== 'en-US') { //  中文
                window.viewer = new Cesium.Viewer("cesiumContainer", {
                    //4 优化
                    // requestRenderMode : true, // 开启渲染模式
                    // maximumRenderTimeChange : Infinity, // 最大渲染时间

                    homeButton: true,
                    sceneModePicker: false,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: true, // 是否显示动画控件
                    infoBox: false, // 是否显示点击要素之后显示的信息
                    selectionIndicator: true, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: true, // 是否显示时间线控件
                    fullscreenButton: false, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),
                    showRenderLoopErrors: false, // 是否显示渲染错误
                    // 地形 ok
                    // terrain: Cesium.Terrain.fromWorldTerrain(), // 地形数据
                    //   地形 ok
                    terrain: Cesium.Terrain.fromWorldTerrain({
                        requestWaterMask: false, // 水面蒙版
                        requestVertexNormals: true  //
                    }),

                });

            } else {  // 英文标注

                window.viewer = new Cesium.Viewer("cesiumContainer", {
                    // 优化
                    // requestRenderMode : true, // 开启渲染模式
                    // maximumRenderTimeChange : Infinity, // 最大渲染时间

                    homeButton: true,
                    sceneModePicker: false,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: true, // 是否显示动画控件
                    infoBox: false, // 是否显示点击要素之后显示的信息
                    selectionIndicator: true, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: true, // 是否显示时间线控件
                    fullscreenButton: false, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),
                    showRenderLoopErrors: false, // 是否显示渲染错误
                    // 地形 ok
                    // terrain: Cesium.Terrain.fromWorldTerrain(), // 地形数据
                    //   地形 ok
                    terrain: Cesium.Terrain.fromWorldTerrain({
                        requestWaterMask: false, // 水面蒙版
                        requestVertexNormals: true  //
                    }),
                    // 英文标注
                    baseLayer: Cesium.ImageryLayer.fromWorldImagery({
                        style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
                    }),

                });
            }




            // 显示地图阴影
            // window.viewer.shadows= true;
            // window.viewer.scene.globe.enableLighting = true;
            // 显示帧率组件
            window.viewer.scene.debugShowFramesPerSecond = true;
            // 解决低性能设备黑屏
            window.viewer.scene.globe.showGroundAtmosphere = false;
            //是否开启抗锯齿
            // window.viewer.scene.fxaa = true;
            // window.viewer.scene.postProcessStages.fxaa.enabled = true;

            //  TODO 优化性能
            // window.viewer.scene.requestRenderMode = true;   // 开启渲染模式 加速
            // window.viewer.scene.debugShowFramesPerSecond = true;
            // window.viewer.resolutionScale = 0.9; // 默认值为 1.0；

            // window.viewer.scene.msaaSamples = 4 // 抗锯齿
            //是否开启抗锯齿
            // 抗锯齿
            window.viewer.scene.fxaa = true;
            window.viewer.scene.postProcessStages.fxaa.enabled = false;

            viewer.scene.sun.show = false;
            viewer.scene.moon.show = false; // 关闭日月

            // TODO校验如果是电脑端 开启抗锯齿
            const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

            // 简单的User Agent检查示例
            if (/android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase())) {
                // 这是移动设备（手机或平板）
                // this.device = /ipad|tablet/i.test(userAgent.toLowerCase()) ? 'tablet' : 'phone';
                console.log('移动端', userAgent);
                // 优化2


                // 优化2
                // if(Cesium.FeatureDetection.supportsImageRenderingPixelated()){//判断是否支持图像渲染像素化处理
                //     window.viewer.resolutionScale = window.devicePixelRatio;
                //     }

                //     // 2.降低性能提供图片质量
                window.viewer.scene.globe.maximumScreenSpaceError = 4 / 3; // 地图文字清晰地点

                window.viewer._cesiumWidget._supportsImageRenderingPixelated = Cesium.FeatureDetection.supportsImageRenderingPixelated();
                window.viewer._cesiumWidget._forceResize = true;
                if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {
                    var vtxf_dpr = window.devicePixelRatio;
                    // 适度降低分辨率
                    while (vtxf_dpr >= 2.0) {
                        vtxf_dpr /= 2.0;
                    }
                    //alert(dpr);
                    window.viewer.resolutionScale = vtxf_dpr;
                }


            } else {
                // 这是桌面设备
                console.log('桌面端', userAgent);
                // this.device = 'desktop';
                // window.viewer2DP.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿

                // 2 js 性能优化 降低屏幕空间误差，提高渲染效率
                //是否开启抗锯齿
                //  viewer.scene.fxaa = false; // 关闭抗锯齿


                window.viewer.scene.globe.maximumScreenSpaceError = 1.5;
                window.viewer.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
                // window.viewer.resolutionScale = window.devicePixelRatio; // 分辨率

                if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {//判断是否支持图像渲染像素化处理
                    window.viewer.resolutionScale = window.devicePixelRatio;
                }
            }


            // 3.改变地图灰度系数
            // let layer0 = window.viewer.scene.imageryLayers.get(0);
            //     layer0.gamma = 0.66;






            // Hides the stars
            // window.viewer.scene.skyBox.show = false;
            // Explicitly render a new frame
            // window.viewer.scene.requestRender(); // 手动渲染



            // TODO 绘制贴地线   
            // 终点和起点的线条
            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat);
            let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat);
            this.createGroundLineAndBillboards(startPosition, endPosition);



            // 计算距离
            // var distance=Cesium.Cartesian3.distance(this.startPosition,this.endPosition)

            // console.log( "计算直线距离 两点距离" ,distance)

            // console.log("测试开始时间--->:",new Date(1694448065000));


            // window.viewer.zoomTo(circleEntity);

            // this.drawPolyline(this.list);

            // TODO 通过 ID 获取实体
            // const dynamicEntity = window.viewer.entities.getById("enclosure_30");
            // console.log("绘制一个中心透明获取的实体", dynamicEntity.position);


            // TODO 调用模型动画 
            // ./mapGLB/gezia.glb

            // this.modelAnimate(this.list,'/mapGLB/pigeon1.glb');
            // this.modelAnimate(listData, './mapGLB/Cesium_Air.glb');

            // this.modelAnimate(this.list, './mapGLB/pigeon_qujiao.glb');


            // TODO 点击事件
            window.mouseMoveHandler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas);
            var lastHoveredEntity = null; // 用于存储当前悬停的实体

            window.mouseMoveHandler.setInputAction((click) => {
                console.log('左键单击事件：', click.position);

                this.mapTileLevel(window.viewer);
                // // 获取当前时间
                // var currentTime = window.viewer.clock.currentTime;
                // console.log("当前时间按-》", this.CesiumDateTimeXFormatter(currentTime));


                // var ray = window.viewer.camera.getPickRay(click.position);
                // var position = window.viewer.scene.globe.pick(ray, window.viewer.scene);
                // if (position) {
                //     console.log('位置信息position：', position);
                //     console.log('位置信息：', Cesium.Cartographic.fromCartesian(position));
                //     // var cartographicPositions = Cesium.Ellipsoid.WGS84.cartesianArrayToCartographicArray(position);
                //     // console.log('WGS848经纬度信息：x , y , z ',cartographicPositions);
                // }

                var pickedObject = viewer.scene.pick(click.position);
                // 检查是否击中了实体
                if (Cesium.defined(pickedObject) && pickedObject.id instanceof Cesium.Entity) {
                    var entity = pickedObject.id;
                    // console.log('Clicked on entity:', entity.id);
                    const imei = entity.id.split('point_')[1]; // 假设id格式为'point_IMEI'
                    // console.log('Clicked on imei:', imei);
                    let val = {
                        deviceImei: imei
                    }
                    this.handleCurrentChange(val)
                    // 在这里添加你想要执行的操作
                    // 例如，你可以改变实体的颜色或显示一个信息框
                    // entity.material = new Cesium.ColorMaterialProperty(Cesium.Color.RED);
                }

            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            // TODO 鼠标移动时，显示实体的标签
            // window.mouseMoveHandler.setInputAction((movement) => {
            //     var pickedObject = viewer.scene.pick(movement.endPosition);
            //     // 检查是否击中了实体
            //     if (Cesium.defined(pickedObject) && pickedObject.id instanceof Cesium.Entity) {
            //         var entity = pickedObject.id;
            //         // 假设id格式为'point_IMEI'，解析IMEI
            //         const imei = entity.id.split('point_')[1];

            //         // 检查IMEI是否有效
            //         if (imei) {
            //             // 如果存在上一个悬停的实体，并且它不是当前实体，则隐藏其标签
            //             if (lastHoveredEntity && lastHoveredEntity !== entity) {
            //                 lastHoveredEntity.label.show = false;
            //             }

            //             // 显示当前实体的标签
            //             entity.label.show = true;

            //             // 更新最后一个悬停的实体
            //             lastHoveredEntity = entity;

            //             // console.log('Hovered over imei:', imei);
            //         }
            //         // 注意：这里不处理else情况，因为我们不希望在鼠标没有悬停在实体上时隐藏任何标签
            //     }
            //     // 可以取消注释以下行来查看鼠标移动事件的详细信息
            //     // console.log('鼠标移动事件：', movement);
            // }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

            // 定义原始的事件处理函数
            function onMouseMove(movement) {
                var pickedObject = viewer.scene.pick(movement.endPosition);
                if (Cesium.defined(pickedObject) && pickedObject.id instanceof Cesium.Entity) {
                    var entity = pickedObject.id;
                    const imei = entity.id.split('point_')[1];
                    if (imei) {
                        if (lastHoveredEntity && lastHoveredEntity !== entity) {
                            lastHoveredEntity.label.show = false;
                        }
                        entity.label.show = true;
                        lastHoveredEntity = entity;
                    }
                }
            }

            // 使用防抖函数包装原始的事件处理函数
            const debouncedMouseMove = this.debounce(onMouseMove, 100); // 100毫秒的延迟


            // 设置事件处理函数
            window.mouseMoveHandler.setInputAction(debouncedMouseMove, Cesium.ScreenSpaceEventType.MOUSE_MOVE);


            // 自定义主页按钮
            // 保存当前上下文的viewer引用
            // var viewerRef = window.viewer;
            // TODO 自定义主页按钮
            // window.viewer.homeButton.viewModel.command.beforeExecute.addEventListener(function (e) {
            //     e.cancel = true;
            //     // 你要飞的位置
            //     viewerRef.camera.flyTo({ // ( this.startLng, this.startLat);
            //         destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 5000.0),
            //         duration: 1,	// 以秒为单位的飞行持续时间。
            //         offset : {
            //             heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
            //             pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
            //             range: 10								// 到中心的距离，以米为单位。								
            //         }
            //     });
            // });
            window.viewer.homeButton.viewModel.command.beforeExecute.addEventListener((e) => {
                e.cancel = true;
                // 你要飞的位置
                window.viewer.camera.flyTo({
                    destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 500000.0),
                    duration: 1, // 以秒为单位的飞行持续时间。
                    offset: {
                        heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                        pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                        range: 10								// 到中心的距离，以米为单位。								
                    }
                });
            });

            
         

            // 加载OSM在线地图（标准风格）
            // window.viewer.imageryLayers.addImageryProvider(
            //     new Cesium.UrlTemplateImageryProvider({
            //         url: 'https://tile-{s}.openstreetmap.fr/hot/{z}/{x}/{y}.png',
            //         subdomains: ["a", "b", "c", "d"],
            //     })
            // );

            // 添加天地图影像注记底图 注册开发者，设置域名白名单 
            // window.viewer.imageryLayers.addImageryProvider(new Cesium.WebMapTileServiceImageryProvider({
            //     url: "http://t0.tianditu.gov.cn/cia_w/wmts?tk=f180b8af3efcf798a9281dd0f0d3dcae",
            //     layer: "cia",
            //     style: "default",
            //     tileMatrixSetID: "w",
            //     format: "tiles",
            //     maximumLevel: 18
            // }))

            // 从缓存中获取语言 如果 当期系统语言是英文 则不添加高德地图标注
            if (lang !== 'en-US') {
                // TODO 如果需要叠加高德/百度注记地图则添加以下代码 zh_cn   zh_en webrd02      // 从 sessionStorage 获取语言设置，如果没有则默认为 'zh-CN'  'en-US'    'zh-TW'
                window.viewer.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
                    url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
                    layer: "tdtAnnoLayer",
                    style: "default",
                    format: "image/jpeg",
                    tileMatrixSetID: "GoogleMapsCompatible"
                }));
            }




            // 自定义的近地天空盒 近景天空
            let groundSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/lantian/Left.jpg'),
                    positiveX: require('@/assets/skyBox/lantian/Right.jpg'),
                    negativeY: require('@/assets/skyBox/lantian/Back.jpg'),
                    positiveY: require('@/assets/skyBox/lantian/Front.jpg'),
                    negativeZ: require('@/assets/skyBox/lantian/Down.jpg'),
                    positiveZ: require('@/assets/skyBox/lantian/Up.jpg')
                }
            })

            // 自带的默认天空盒
            // let defaultSkybox = window.viewer.scene.skyBox;
            // 自定义的近地天空盒 远处 天空
            let defaultSkybox = new Cesium.SkyBox({
                sources: {
                    negativeX: require('@/assets/skyBox/5/tycho2t3_80_mx.jpg'),
                    positiveX: require('@/assets/skyBox/5/tycho2t3_80_px.jpg'),
                    negativeY: require('@/assets/skyBox/5/tycho2t3_80_my.jpg'),
                    positiveY: require('@/assets/skyBox/5/tycho2t3_80_py.jpg'),
                    negativeZ: require('@/assets/skyBox/5/tycho2t3_80_mz.jpg'),
                    positiveZ: require('@/assets/skyBox/5/tycho2t3_80_pz.jpg')
                }
            })
            // 渲染前监听并判断相机位置
            window.viewer.scene.preUpdate.addEventListener(() => {
                let position = window.viewer.scene.camera.position;
                let cameraHeight = Cesium.Cartographic.fromCartesian(position).height;
                if (cameraHeight < 240000) {
                    window.viewer.scene.skyBox = groundSkybox;
                    window.viewer.scene.skyAtmosphere.show = false;
                } else {
                    window.viewer.scene.skyBox = defaultSkybox;
                    window.viewer.scene.skyAtmosphere.show = true;
                }
            })





            // 立即设置时间格式化函数
            window.viewer.animation.viewModel.dateFormatter = this.CesiumDateFormatter;
            window.viewer.animation.viewModel.timeFormatter = this.CesiumTimeFormatter;
            Cesium.Timeline.prototype.makeLabel = this.CesiumDateTimeFormatter;

            // TODO 设置始时钟始时间
            window.viewer.clock.startTime = this.startTime.clone();

            // const julianDate = Cesium.JulianDate.fromDate(utcDate);  2023-11-08 08:32:00  1699403520000

            // let MendTime = moment.tz('2023-11-08 08:32:00', 'YYYY-MM-DD HH:mm:ss', 'Asia/Shanghai');
            // let currentTime = Cesium.JulianDate.fromDate(MendTime.toDate()); // 中国时间 开始时间 toDate
            // console.log("设置当前时间-->", currentTime);
            // // window.alert("设置当前时间-->"+currentTime);
            // // 设置时钟当前时间
            // window.viewer.clock.currentTime = currentTime.clone();

            window.viewer.clock.currentTime = this.startTime.clone();
            // 时间速率，数字越大时间过的越快 TODO 倍速播发
            window.viewer.clock.multiplier = 60;
            window.viewer.clock.stopTime = this.stopTime.clone();

            // 假设start已经在之前定义过了，否则你需要从firstProperty中获取startTime
            // var start = times[0]; // 如果需要从第一个轨迹中获取开始时间的话

            // 时间轴（使用第一个轨迹的开始和停止时间）
            window.viewer.timeline.zoomTo(this.startTime, this.stopTime);

            // 循环执行，到达终止时间，重新从起点时间开始
            window.viewer.clock.clockRange = Cesium.ClockRange.LOOP_STOP;


            // var circleRadius_30 =  20000.0; // 米作为单位，这里 红
            // var circleRadius_100 = 35000.0; // 使用米作为单位，这里 蓝色 
            // var circleRadius_200 = 50000.0; // 使用米作为单位，这里 绿色
            // var circleRadius_300 = 65000.0; // 半径为使用米作为单位，这里 紫色
            // var circleRadius_400 = 80000.0; // 半径，这里 黄

            // 、、 朝向

            // 计算从起点至终点的方向向量


            // 计算方向向量 startPosition  endPosition
            const direction = Cesium.Cartesian3.subtract(endPosition, startPosition, new Cesium.Cartesian3());
            const normalizedDirection = Cesium.Cartesian3.normalize(direction, new Cesium.Cartesian3());
            const face = Math.atan2(normalizedDirection.y, normalizedDirection.x);
            // Cesium.Math.toRadians()
            console.log("朝向a", face);



            // TODO 绘制实体圆圈

            this.createCircleEntity(face, endPosition, this.circleRadius_30, 'enclosure_30', "#ff0000");
            this.createCircleEntity(face, endPosition, this.circleRadius_100, 'enclosure_100', "#0000ff");
            this.createCircleEntity(face, endPosition, this.circleRadius_200, 'enclosure_200', "#00ff00");
            this.createCircleEntity(face, endPosition, this.circleRadius_300, 'enclosure_300', "#ff00ff");
            this.createCircleEntity(face, endPosition, this.circleRadius_400, 'enclosure_400', "#ffff00");


            // 通过 ID 获取实体
            const dynamicEntity_30 = window.viewer.entities.getById("enclosure_30");
            const dynamicEntity_100 = window.viewer.entities.getById("enclosure_100");
            const dynamicEntity_200 = window.viewer.entities.getById("enclosure_200");
            const dynamicEntity_300 = window.viewer.entities.getById("enclosure_300");
            const dynamicEntity_400 = window.viewer.entities.getById("enclosure_400");

            // console.log("绘制一个中心透明获取的实体position", dynamicEntity.position.getValue());

            this.circleCenter_30 = dynamicEntity_30.position.getValue();
            this.circleCenter_100 = dynamicEntity_100.position.getValue();
            this.circleCenter_200 = dynamicEntity_200.position.getValue();
            this.circleCenter_300 = dynamicEntity_300.position.getValue();
            this.circleCenter_400 = dynamicEntity_400.position.getValue();

            //    、、 地线的方向向量
            // const direction = Cesium.Cartesian3.normalize(Cesium.Cartesian3.subtract(endPosition, startPosition, new Cesium.Cartesian3()), new Cesium.Cartesian3());
            // let labelPixelOffset;
            // if (Math.abs(direction.y) > 0.5) {
            //     // 如果贴地线接近垂直，我们将标签水平偏移
            //     labelPixelOffset = new Cesium.Cartesian2(0, -20); // 根据需要调整偏移量
            // } else {
            //     // 如果贴地线接近水平，我们将标签垂直偏移（但在这个例子中，我们仍然水平偏移以模拟朝向）
            //     // 注意：这只是一个近似的解决方案，因为Cesium的标签不支持真正的旋转
            //     labelPixelOffset = new Cesium.Cartesian2(20, 0); // 根据需要以及贴地线的具体方向调整偏移量
            //     // 如果需要更精确的朝向控制，可能需要考虑更复杂的几何计算或使用其他Cesium功能（如Billboard集合和自定义着色器）
            // }

            // 计算方向向量
            // 使用 Cesium 的 Cartographic.fromCartesian 方法将 Cartesian3 坐标转换回地理坐标（经纬度加高度）


            // // 现在 azimuthRadians 包含了从起点到终点的方位角（弧度）
            // console.log("Azimuth in radians:", azimuthRadians);

            //             console.log("地线的方向向量",azimuthRadians);
            // console.log("绘制一个中心透明获取的实体positioncircleCenter_30", this.circleCenter_100);

            // 计算圆边缘在180度（正南方向）的坐标
            // const centerCartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(endPosition);
            // const angleInRadians = Cesium.Math.toRadians(180.0); // 180度转换为弧度
            // const radiusInMeters = 3000;

            // // 计算圆边缘的笛卡尔坐标
            // const edgeCartographic = new Cesium.Cartographic(
            //     centerCartographic.longitude,
            //     centerCartographic.latitude - Cesium.Math.toRadians(radiusInMeters / Cesium.Ellipsoid.WGS84.maximumRadius), // 近似计算纬度变化
            //     centerCartographic.height
            // );
            // const edgeCartesian = Cesium.Ellipsoid.WGS84.cartographicToCartesian(edgeCartographic);



            // 在 400 里面 大于300 的 小于 400的  是黄色 
            // 300 里面 大于200 的 小于 300的  是紫色
            // 200 里面 大于100 的 小于 200的  是绿色
            // 100 里面 大于30 的 小于 100的  是蓝色
            // 小于30 是红色
            // 其它是白色



            // 确保时间轴更新
            // window.viewer.timeline.updateFromClock();
            // window.viewer.timeline.zoomTo(window.viewer.clock.startTime, window.viewer.clock.stopTime);

            // // // TODO 9999最小缩放高度（米）2300 1200
            window.viewer.scene.screenSpaceCameraController.minimumZoomDistance = 7300;
            // // // 最大缩放高度（米）
            // window.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 8000;

            // 鼠标滚轮控制视角
            // window.viewer.scene.screenSpaceCameraController.tiltEventTypes = [Cesium.CameraEventType.RIGHT_DRAG];
            // window.viewer.scene.screenSpaceCameraController.tiltEventTypes = [ Cesium.CameraEventType.LEFT_DRAG ];
            window.viewer.scene.screenSpaceCameraController.tiltEventTypes = [Cesium.CameraEventType.RIGHT_DRAG];





        },
        // TODO创建一个防抖函数
        debounce(func, wait) {
            let timeout;
            return function (...args) {
                const context = this;
                clearTimeout(timeout);
                timeout = setTimeout(() => func.apply(context, args), wait);
            };
        },

        // TODO 初始化3D 地图数据 initCesiumMapData2D initCesium3D
        initCesiumMapData3D(listData) {
            // 使用handleSnow方法
            // handleSnow(window.viewer, 90.0);


            // //     // CanvasWindy
            //     window.windy = new Windy(this.WindyDataJson, window.viewer);

            //     window.timer = setInterval(function () {
            //         window.windy.animate();
            //     }, 300);



            // window.viewer.entities.removeAll(); // 移除所有实体
            // TODO 移除所有鸽子实体 排除地图上是否有这个实体了则不添加 删除存在的
            // 3D 获取所有以'point_'开头的实体
            var excludePointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (excludePointEntities.length != 0) {

                excludePointEntities.forEach(entity => {
                    window.viewer.entities.remove(entity);  // 删除实体
                    // entity.show = false;         // 隐藏所有实体
                });
            }

            // TODO 88888888888 调用模型动画 
            // ./mapGLB/gezia.glb

            // this.modelAnimate(this.list,'/mapGLB/pigeon1.glb');
            // this.modelAnimate(listData, './mapGLB/Cesium_Air.glb');

            // this.modelAnimate(listData, './mapGLB/pigeon_qujiao.glb');
            // this.modelAnimate(listData, './mapGLB/飞高空浅红.glb');
            // /Users/mac/Downloads/glbxz_com.glb /Users/mac/Downloads/glbxz_2.glb
            this.modelAnimate(listData, '');


            // TODO 这里 111啊 监听时钟变化

            window.viewer.clock.onTick.addEventListener(this.mOnTick);

        },

        // TODO 初始化2D 地图
        initCesium2D() {

            (Cesium.Ion.defaultAccessToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmY2Q4ZjNhYy0yM2FhLTRlNmItOGE2OC00ZDA1MTVlNzYyNmMiLCJpZCI6MjUxMjMxLCJpYXQiOjE3MzA0NTIyMjh9.fWsd13QqoCAG-EtYrZFXt_4Wqsy4tmn-yJ4MEuMvIIg");



            // 从缓存中获取语言 如果 当期系统语言是英文 则不添加高德地图标注
            const lang = window.sessionStorage.getItem('lang');

            if (lang !== 'en-US') { //  中文
                window.viewer2D = new Cesium.Viewer('cesiumContainer2D', {
                    homeButton: false,
                    sceneModePicker: false,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: true, // 是否显示动画控件 去掉会报错，关联了模型移动 用css 把他隐藏了
                    infoBox: false, // 是否显示点击要素之后显示的信息
                    selectionIndicator: false, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: false, // 是否显示时间线控件
                    fullscreenButton: false, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),
                    showRenderLoopErrors: false, // 是否显示渲染错误
                });

            } else {  // 英文标注

                window.viewer2D = new Cesium.Viewer('cesiumContainer2D', {
                    homeButton: false,
                    sceneModePicker: false,  // 地图模式
                    baseLayerPicker: false, // 影像切换
                    animation: true, // 是否显示动画控件 去掉会报错，关联了模型移动 用css 把他隐藏了
                    infoBox: false, // 是否显示点击要素之后显示的信息
                    selectionIndicator: false, // 要素选中框
                    geocoder: false, // 是否显示地名查找控件
                    timeline: false, // 是否显示时间线控件
                    fullscreenButton: false, // 是否显示全屏控件
                    shouldAnimate: false,   // 默认不播放动画
                    navigationHelpButton: false, // 是否显示帮助信息控件
                    creditContainer: document.createElement("div"),
                    showRenderLoopErrors: false, // 是否显示渲染错误
                    // 英文标注
                    baseLayer: Cesium.ImageryLayer.fromWorldImagery({
                        style: Cesium.IonWorldImageryStyle.AERIAL_WITH_LABELS,
                    }),

                });
            }



            // 在Viewer实例创建后，立即将场景模式设置为2D
            window.viewer2D.scene.mode = Cesium.SceneMode.SCENE2D;
            // window.viewer2D.camera.enabled = false; // 关闭三维场景的相机
            // 显示帧率组件
            //  window.viewer.scene.debugShowFramesPerSecond = true;

            // 提高地图清晰度
            window.viewer2D.scene.postProcessStages.fxaa.enabled = true; // 开启抗锯齿
            window.viewer2D.scene.globe.maximumScreenSpaceError = 4 / 3;
            window.viewer2D.highDynamicRangeSupported && (window.viewer.scene.highDynamicRange = true); // 开启高动态范围渲染
            // window.viewer2D.resolutionScale = window.devicePixelRatio; // 分辨率

            // 优化2
            if (Cesium.FeatureDetection.supportsImageRenderingPixelated()) {//判断是否支持图像渲染像素化处理
                window.viewer2D.resolutionScale = window.devicePixelRatio;
            }




        },
        // TODO初始化2D 地图数据
        initCesiumMapData2D(listData) {
            // 从缓存中获取语言 如果 当期系统语言是英文 则不添加高德地图标注
            const lang = window.sessionStorage.getItem('lang');

            if (lang !== 'en-US') { //  中文
                // 如果需要叠加高德/百度注记地图则添加以下代码
                window.viewer2D.imageryLayers.addImageryProvider(new Cesium.UrlTemplateImageryProvider({
                    url: "http://webst02.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
                    layer: "tdtAnnoLayer",
                    style: "default",
                    format: "image/jpeg",
                    tileMatrixSetID: "GoogleMapsCompatible"
                }))
            }

            window.viewer2D.entities.removeAll(); // 移除所有实体

            // 绘制轨迹线条
            this.addLine(listData);

            let properties = this.computeFlight(listData); // 注意这里变量名改为properties，因为返回的是数组

            // console.log("properties",properties);
            // 为每个轨迹创建一个实体（这里仅以第一个轨迹为例）
            properties.forEach((property, index) => {


                let planeModel = window.viewer2D.entities.add({
                    id: "model_" + listData[index][0].deviceImei,    // 标识符
                    // 和时间轴关联（可以使用每个轨迹自己的时间范围，这里以第一个轨迹的时间为例）
                    availability: new Cesium.TimeIntervalCollection([

                        new Cesium.TimeInterval({
                            start: this.startTime, // 可以根据需要设置为每个轨迹自己的开始时间
                            stop: this.stopTime, // 可以根据需要设置为每个轨迹自己的停止时间
                        }),
                    ]),
                    position: property, // 使用当前轨迹的SampledPositionProperty
                    // 根据所提供的速度计算模型的朝向
                    orientation: new Cesium.VelocityOrientationProperty(property),
                    // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）

                    model: {
                        uri: './mapGLB/pigeon_2D.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]  pigeon2D
                        // scale: 1, // 
                        minimumPixelSize: 100,  // 最小的模型像素
                        maximumScale: 2000,
                        runAnimations: false,//是否显示动画

                        //   color: Cesium.Color.fromBytes(255, 0, 0, 128), // 设置颜色
                        // clampAnimations: true,//是否保持最后一针的动画
                        // /裁剪 ok
                        // clippingPlanes : new Cesium.ClippingPlaneCollection({
                        //     planes: [
                        //         new Cesium.ClippingPlane(new Cesium.Cartesian3(0,0,1), 0),

                        //     ],
                        //     edgeWidth: 1,
                        //     edgeColor:  Cesium.Color.RED,
                        //     enabled: true,
                        //     edgeMaterial: new Cesium.PolylineOutlineMaterialProperty({
                        //         color: Cesium.Color.RED,
                        //         outerWidth:1,
                        //         outlineColor: Cesium.Color.BLACK,

                        //     }),
                        // })

                    },


                    // 图片
                    //  billboard: {
                    //     image: require('@/assets/bike_online.png'),
                    //     scale : 1,       // 大小
                    //     // sizeInMeters: true,  // 大小是否以米为单位
                    //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                    //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                    //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                    //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                    //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                    //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                    //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

                    //     },
                    // 点

                })
            });


            // 立即设置时间格式化函数
            window.viewer2D.animation.viewModel.dateFormatter = this.CesiumDateFormatter;
            window.viewer2D.animation.viewModel.timeFormatter = this.CesiumTimeFormatter;
            // Cesium.Timeline.prototype.makeLabel = this.CesiumDateTimeFormatter; // 时间轴刻度显示



            // console.log("初始化2D地图", this.startLng, this.startLat);
            // TODO 默认视角


            // let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 150000.0);
            // let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat, 150000.0);
            // // 计算中点
            // let midpoint = Cesium.Cartesian3.midpoint(startPosition, endPosition, new Cesium.Cartesian3());


            // window.viewer2D.camera.flyTo({
            //     destination: midpoint,
            //     duration: 1, // 以秒为单位的飞行持续时间。
            //     offset: {
            //         heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
            //         pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
            //         range: 10								// 到中心的距离，以米为单位。								
            //     }
            // });


        },
        // TODO 跳转2D地图 起点终点位置
        jump2DMapPosition() {
            let startPosition = Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 150000.0);
            let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng, this.endLat, 150000.0);
            // 计算中点
            let midpoint = Cesium.Cartesian3.midpoint(startPosition, endPosition, new Cesium.Cartesian3());


            window.viewer2D.camera.flyTo({
                destination: midpoint,
                duration: 1, // 以秒为单位的飞行持续时间。
                offset: {
                    heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
                    pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
                    range: 10								// 到中心的距离，以米为单位。								
                }
            });
        },

        // TODO 同步时间的函数
        synchronizeClocks() {
            var currentTime = window.viewer.clock.currentTime; // 获取当前时间
            var clockRange = window.viewer.clock.clockRange; // 时钟范围
            var clockMultiplier = window.viewer.clock.multiplier; // 时钟倍数
            var shouldAnimate = window.viewer.clock.shouldAnimate; // 是否播放
            // 设置始时钟始时间
            var startTime = window.viewer.clock.startTime;
            var stopTime = window.viewer.clock.stopTime;
            // var zoomTo =  window.viewer.timeline.zoomTo;

            // 设置小地图的时间
            window.viewer2D.clock.currentTime = currentTime;
            window.viewer2D.clock.clockRange = clockRange;
            window.viewer2D.clock.multiplier = clockMultiplier;
            window.viewer2D.clock.shouldAnimate = shouldAnimate;
            window.viewer2D.clock.startTime = startTime;
            window.viewer2D.clock.stopTime = stopTime;
            // window.viewer2D.timeline.zoomTo = zoomTo;
            // 同步时钟

            // 如果主地图正在播放，则确保小地图也在播放
            if (shouldAnimate) {
                window.viewer2D.clock.shouldAnimate = false;
            } else {
                window.viewer2D.clock.shouldAnimate = true;
            }

        },

        // TODO 改变2D 地图边框颜色
        changeBorderColor(newColor) {
            this.borderColor2D = newColor;
        },

        // TODO 封装方法 绘制贴地线
        createGroundLineAndBillboards(startPosition, endPosition) {
            // 创建贴地线
            var instance = new Cesium.GeometryInstance({
                geometry: new Cesium.GroundPolylineGeometry({
                    positions: [
                        startPosition,
                        endPosition
                    ],
                    width: 1.0
                }),
                id: 'object returned when this instance is picked and to get/set per-instance attributes'
            });

            window.viewer.scene.groundPrimitives.add(new Cesium.GroundPolylinePrimitive({
                geometryInstances: instance,
                appearance: new Cesium.PolylineMaterialAppearance({
                    material: Cesium.Material.fromType('#FF00ff')
                })
            }));

            // 设置视野
            //这是让你的画面以一个怎样的形式出现，相当于出场动画
            // window.viewer.camera.flyTo({
            //     //   lon: 116.405419,
            //     // lat: 39.918034,
            //     // fromDegrees()方法，将经纬度和高程转换为世界坐标，这里定位到中国  101.8, 33.74,  121.795693, 25.146142
            //     //                     heading-代表镜头左右方向,正值为右,负值为左,360度和0度是一样的
            //     // pitch-代表镜头上下方向,正值为上,负值为下.
            //     // roll-代表镜头左右倾斜.正值,向右倾斜,负值向左倾斜、
            //     // Cesium.Cartesian3.fromDegreesArrayHeights( [this.startLng, this.startLat,5000,this.endLng,  this.endLat, 5000]),
            //     //   let startPosition = Cesium.Cartesian3.fromDegrees( this.startLng, this.startLat);
            //     // let endPosition = Cesium.Cartesian3.fromDegrees(this.endLng,  this.endLat);

            //     destination: Cesium.Cartesian3.fromDegrees(this.startLng, this.startLat, 500000),
            //     // orientation: {
            //     //     // 指向 z 180
            //     //     // heading:Cesium.Math.toRadians(-10),
            //     //     // 视角 y
            //     //     pitch:Cesium.Math.toRadians(-90),
            //     //     // 视角 x
            //     //     roll: -3.0,
            //     // },
            //     duration: ,	// 以秒为单位的飞行持续时间。
            //     offset: {
            //         heading: Cesium.Math.toRadians(0.0),	// 以弧度为单位的航向角。
            //         pitch: -Math.PI / 2,					// 以弧度为单位的俯仰角。
            //         range: 10								// 到中心的距离，以米为单位。								
            //     }
            // });

            this.flyToStartPoint();

            // 在起点添加广告牌
            window.viewer.entities.add({
                position: startPosition,
                // billboard: {
                //     image: require('@/assets/bike_online.png'),
                // scale : 1.5,       // 大小
                //     // alignedAxis: Cesium.Cartesian3.UNIT_Z, // 设置广告牌始终面向相机
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考

                //     // sizeInMeters: true,  // 大小是否以米为单位
                //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                // },
                // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                model: {
                    uri: './mapGLB/start2.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    // scale: 1, // 
                    minimumPixelSize: 128,  // 最小的模型像素
                    maximumScale: 200,
                    //   runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    horizontalOrigin: Cesium.HorizontalOrigin.BOTTOM, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向

                    pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平  -180
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                    runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画
                },
                label: {
                    text: this.$t('phoneLanguage.startPoint'),
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    scale: 0.5,       // 大小
                    font: '48px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Material.fromType('#00ff00'),
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, -140), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });


            // // 在终点添加广告牌
            window.viewer.entities.add({
                position: endPosition,
                // billboard: {
                //     image: require('@/assets/bike_online.png'),
                //     scale : 1.5,       // 大小
                //     // sizeInMeters: true,  // 大小是否以米为单位
                //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                //     heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏
                // },
                model: {
                    uri: './mapGLB/end3.glb', // 可以根据index加载不同的模型，例如：modelGltfArray[index]
                    scale: 1, // 
                    minimumPixelSize: 128,  // 最小的模型像素
                    maximumScale: 200,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    pixelOffset: new Cesium.Cartesian2(0, -180), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面 
                    runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画

                },
                label: {
                    text: this.$t('phoneLanguage.endPoint'),

                    scale: 0.5,       // 大小
                    font: '48px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    fillColor: Cesium.Color.RED,
                    outlineColor: Cesium.Color.BLACK, // 轮廓线
                    outlineWidth: 2,    // // 轮廓线宽度
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                    // showBackground: true, // 背景
                    // backgroundColor: Cesium.Color.BLUE,
                    pixelOffset: new Cesium.Cartesian2(0, -140), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                    eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                }
            });
        },
        // TODO 方法 绘制圈
        createCircleEntity(face, position, radius, id, color) {


            // 计算180度位置（即圆心正对面）的坐标
            // const radians = (Math.PI) / 2; // (Math.PI) 180度转换为弧度   (Math.PI) / 2 90度转换为弧度 

            const offset = new Cesium.Cartesian3(
                radius * Math.cos(face),
                0,
                radius * Math.sin(face)
            );
            const positionOnCircle = Cesium.Cartesian3.add(position, offset, new Cesium.Cartesian3());

            // 计算指定方向上的点坐标（这里假设是在二维平面上，z=0）
            // const offsetX = radius * Math.cos(directionRadians);
            // const offsetY = radius * Math.sin(directionRadians);
            // const offset = new Cesium.Cartesian3(offsetX, offsetY, 0);
            // const positionOnCircle = Cesium.Cartesian3.add(position, offset, new Cesium.Cartesian3());


            const km = radius / 1000;
            // 绘制公里文字
            //    // TODO 绘制公里
            window.viewer.entities.add({
                show: false,
                id: id + this.$t('phoneLanguage.kilometer'), // 
                position: positionOnCircle,
                // 广告牌
                label: {
                    text: km + this.$t('phoneLanguage.kilometer'),
                    // scale : 1.5,       // 大小
                    font: '24px sans-serif', // 设置字体大小
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                    style: Cesium.LabelStyle.FILL_AND_OUTLINE,
                    fillColor: Cesium.Color.WHITE,
                    outlineColor: Cesium.Color.BLACK,
                    outlineWidth: 2,
                    verticalOrigin: Cesium.VerticalOrigin.CENTER,
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考


                    pixelOffset: new Cesium.Cartesian2(5, -20), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                },
            });



            // 绘制圆
            var circleEntity = window.viewer.entities.add({
                show: false,
                id: id,
                position: position, // 设置圆心位置
                name: id, // 使用ID作为实体名称
                ellipse: {
                    semiMinorAxis: radius, // 设置椭圆的短半轴，与长半轴相等，形成圆
                    semiMajorAxis: radius, // 设置椭圆的长半轴
                    extrudedHeight: 1000, // 设置高度
                    // height: 0, // 设置高度为0，使其贴合地面
                    material: Cesium.Color.fromCssColorString('#ffffff00'),// 设置填充颜色为绿色但完全透明

                    outline: true, // 启用外边框
                    // 可以根据需要设置outlineColor和outlineWidth，或者作为额外参数传入
                    outlineColor: Cesium.Color.fromCssColorString(color), // 默认设置外边框颜色为红色
                    outlineWidth: 2 // 默认设置外边框宽度为2
                }
            });


            return circleEntity; // 返回创建的实体对象，以便后续操作（可选）
        },

        // TODO 方法 隐藏显示鸽子设备
        hideShowDevice(deviceImeiArray) {

            // 获取所有以'point_'开头的鸽子实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 先把所有的隐藏
            pointEntities.forEach(entity => {
                entity.show = false;
            });


            // 确保传入的数组有效
            if (!Array.isArray(deviceImeiArray) || deviceImeiArray.length === 0) {
                // console.warn('传入的设备号数组无效或为空');
                return;
            }



            // 创建一个映射，用于快速查找实体是否存在于设备号数组中
            const imeiToEntityMap = {};
            pointEntities.forEach(entity => {
                const imei = entity.id.split('_')[1]; // 假设id格式为'point_IMEI'
                imeiToEntityMap[imei] = entity;
            });
            // 
            // 判断是否只有一个设备，则显示设备
            if (deviceImeiArray.length === 1) {
                deviceImeiArray.forEach(imei => {
                    const entity = imeiToEntityMap[imei];
                    if (entity) {
                        entity.show = true;
                        // 如果实体存在，显示它（例如，改变标签颜色为红色，并跟踪它）
                        window.viewer.trackedEntity = entity;
                        // entity.label.fillColor = Cesium.Color.RED;
                        // console.log('显示设备号: ' + imei);

                        // window.viewer.trackedEntity = entity; // 可以考虑是否每次都要设置trackedEntity
                    } else {
                        // 如果实体不存在，理论上应该隐藏它，但由于我们没有直接隐藏实体的方法，
                        // 我们可以选择不设置它为trackedEntity，或者如果有标签，将其颜色设置为不可见或白色
                        // console.warn('未找到设备号: ' + imei + ' 的实体');
                    }
                });
            } else {

                // 遍历设备号数组，处理每个设备号
                deviceImeiArray.forEach(imei => {
                    const entity = imeiToEntityMap[imei];
                    if (entity) {
                        entity.show = true;

                        // // 如果实体存在，显示它（例如，改变标签颜色为红色，并跟踪它）
                        // if (entity.label) {

                        //     // entity.label.fillColor = Cesium.Color.RED;
                        //     console.log('显示设备号: ' + imei);
                        // }
                        // window.viewer.trackedEntity = entity; // 可以考虑是否每次都要设置trackedEntity
                    } else {
                        // 如果实体不存在，理论上应该隐藏它，但由于我们没有直接隐藏实体的方法，
                        // 我们可以选择不设置它为trackedEntity，或者如果有标签，将其颜色设置为不可见或白色
                        // console.warn('未找到设备号: ' + imei + ' 的实体');
                    }
                });
            }



            // 隐藏2D 所有数据
            var pointEntities2D = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('model_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities2D.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointEntities2D.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = true;         // 隐藏所有实体
            });

            // 隐藏2D轨迹线 polyline_
            var pointPolyline2D = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('polyline_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointPolyline2D.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointPolyline2D.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = true;         // 隐藏
            });


        },
        //TODO 移除，隐藏 所有3D 和 2D 鸽子实体 
        removeAllEntity() {
            this.processedTableData = [];
            // 3D 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointEntities.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 隐藏所有实体
            });


            // 隐藏2D 所有数据
            var pointEntities2D = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('model_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities2D.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointEntities2D.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 隐藏所有实体
            });

            // 隐藏2D轨迹线 polyline_
            var pointPolyline2D = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('polyline_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointPolyline2D.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointPolyline2D.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 隐藏
            });


        },
        //TODO 显示 圈围框 enclosure_
        removeAllModel() {

            // 隐藏 圈围框 enclosure_
            // 获取所有以'enclosure_'开头的实体
            var fenceEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('enclosure_');
            });

            // 如果没有找到任何实体，则退出函数
            if (fenceEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            fenceEntities.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = true;         // 显示所有实体


            });


            // 3D 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointEntities.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.model.show = false;         // 隐藏所有实体
                entity.path.show = false;         // 隐藏所有线条
                entity.point.show = true;      // 显示点

            });




        },
        // TODO 隐藏 圈围框 enclosure_
        showAllModel() {

            // 隐藏 圈围框 enclosure_
            // 获取所有以'enclosure_'开头的实体
            var fenceEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('enclosure_');
            });

            // 如果没有找到任何实体，则退出函数
            if (fenceEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            fenceEntities.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 


            });



            // 3D 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointEntities.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.model.show = true;         // 显示所有实体
                entity.path.show = true;         // 显示所有线条
                entity.point.show = false;      // 隐藏点

            });




        },

        //TODO 移除，隐藏 所有2D鸽子实体 
        removeAllEntity2D() {
            // 获取所有以'model_'开头的实体
            var pointEntities = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('model_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointEntities.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = false;         // 隐藏所有实体
            });
            // for (var i = 0; i < pointEntities.length; i++) {
            //     window.viewer.entities.remove(pointEntities[i]);
            // }

        },

        // TODO 全选 显示2D 3D 所有实体 线条
        showAllEntity() {
            this.processTableData();
            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            pointEntities.forEach(function (entity) {
                entity.show = true;         // 显示所有实体
            });

            // 隐藏2D 所有数据
            var pointEntities2D = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('model_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities2D.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointEntities2D.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = true;         // 隐藏所有实体
            });

            // 隐藏2D轨迹线 polyline_
            var pointPolyline2D = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('polyline_');
            });

            // 如果没有找到任何实体，则退出函数
            if (pointPolyline2D.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }
            // 删除所有实体 用foer循环删除
            // pointEntities.forEach(function (entity) {
            //      window.viewer.entities.remove(entity);
            // });
            pointPolyline2D.forEach(entity => {
                // window.viewer.entities.remove(entity);  // 删除所有实体
                entity.show = true;         // 隐藏
            });



        },

        // TODO 3D 根据设备号找轨迹线 改变线条的大小  polyline_
        setPolylineWidth3D(deviceImei, width) {
            // 获取所有以'polyline_'开头的实体
            // point_
            var polylineEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('polyline_');
            });

            // 如果没有找到任何实体，则退出函数
            // 如果没有找到任何实体，则退出函数
            if (polylineEntities.length === 0) {
                console.log("隐藏没有找到以'marker_'开头的实体");
                return;
            }

            console.log("3D 根据设备号找轨迹线", polylineEntities.length);

            // 遍历所有找到的实体
            polylineEntities.forEach(entity => {
                console.log("3D 根据设备号找轨迹线", entity._id, entity._color);
                // // 改变线条的宽度
                // entity.polyline.width = width;
                // 显示线条
                entity.show = true;
                // 直接把3D地图上的轨迹线 添加到 2d地图上
                window.viewer2D.entities.add(entity)

            });


        },
        // TODO 改变2D小地图图片显示在最前面
        setBillboardOrder(deviceImei) {
            // 获取所有以'billboard_'开头的实体
            var billboardEntities = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_' + deviceImei);
            });

            // 如果没有找到任何实体，则退出函数
            if (billboardEntities.length === 0) {
                console.log("隐藏没有找到以'point_'开头的实体");
                return;
            }
            billboardEntities.forEach(entity => {
                // 设置显示在最前面
                // entity.billboard.pixelOffset = new Cesium.Cartesian2(35, 0)


            });
        },

        // TODO 改变2D小地图线条的大小  根据设备号找轨迹线 polyline_
        setPolylineWidth(deviceImei, width) {
            // 获取所有以'polyline_'开头的实体 polyline_867585332227356
            var polylineEntities = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('polyline_' + deviceImei);
            });

            // 如果没有找到任何实体，则退出函数
            // 如果没有找到任何实体，则退出函数
            if (polylineEntities.length === 0) {
                console.log("隐藏没有找到以'polyline_'开头的实体");
                return;
            }
            polylineEntities.forEach(entity => {

                // 改变线条的宽度
                entity.polyline.width = width;
                // 不贴地
                entity.polyline.clampToGround = false;
                // 设置显示在最前面

                // 设置不与地形进行深度测试，以显示在最前面
                entity.polyline.depthTestAgainstTerrain = false;


            });


        },
        // TODO 重置线条 2D小地图  恢复原来大小
        resetPolylineWidth() {
            // 获取所有以'polyline_'开头的实体
            let polylineEntities = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('polyline_');
            });

            // 如果没有找到任何实体，则退出函数
            if (polylineEntities.length === 0) {
                console.log("隐藏没有找到以'polyline_'开头的实体");
                return;
            }
            polylineEntities.forEach(entity => {

                // 改变线条的宽度
                entity.polyline.width = 2;
                // entity.zIndex = 2;
                entity.polyline.clampToGround = true;

            });
        },

        // TODO 改变2D小地图 模型颜色
        setmodelColor(deviceImei, color) {


            // 模型颜色 point_
            let modelEntities = window.viewer2D.entities.values.filter(function (entity) {
                return entity.id.startsWith('model_' + deviceImei);
            });

            // console.log("改变2D小地图 模型颜色",modelEntities);
            // 如果没有找到任何实体，则退出函数
            if (modelEntities.length === 0) {
                console.log("改变2D小地图 模型颜色 隐藏没有找到以'point_'开头的实体");
                return;
            }

            // 改变模型颜色
            modelEntities.forEach((entity, index) => {
                entity.show = true;
                // 改变线条的宽度
                entity.model.color = Cesium.Color.fromCssColorString(color);



            });

        },


        // TODO  优化 不行 绘制广告牌
        // 起点经度纬度，和终点经度纬度，和圆的中心点经度纬度，和圆的半径，找出圆边和线的相交点绘制出广告牌 30公里
        createIntersectionBillboards(startLon, startLat, endLon, endLat, circleLon, circleLat, radiusInKm) {
            // 将经纬度转换为笛卡尔坐标
            function cartographicToCartesian(lon, lat, height = 0) {
                const cartographic = Cesium.Cartographic.fromDegrees(lon, lat, height);
                return Cesium.Ellipsoid.WGS84.cartographicToCartesian(cartographic);
            }

            // 计算线段与圆的相交点
            function lineCircleIntersection(lineStart, lineEnd, circleCenter, radius) {
                const direction = Cesium.Cartesian3.subtract(lineEnd, lineStart, new Cesium.Cartesian3());
                Cesium.Cartesian3.normalize(direction, direction);

                const toCenter = Cesium.Cartesian3.subtract(circleCenter, lineStart, new Cesium.Cartesian3());
                const a = Cesium.Cartesian3.dot(direction, direction);
                const b = 2.0 * Cesium.Cartesian3.dot(direction, toCenter);
                const c = Cesium.Cartesian3.dot(toCenter, toCenter) - radius * radius;

                const discriminant = b * b - 4 * a * c;

                const intersections = [];
                if (discriminant >= 0) {
                    const t1 = (-b - Math.sqrt(discriminant)) / (2.0 * a);
                    const t2 = (-b + Math.sqrt(discriminant)) / (2.0 * a);

                    if (t1 >= 0 && t1 <= 1) {
                        intersections.push(Cesium.Cartesian3.add(lineStart, Cesium.Cartesian3.multiplyByScalar(direction, t1, new Cesium.Cartesian3())));
                    }
                    if (t2 >= 0 && t2 <= 1) {
                        intersections.push(Cesium.Cartesian3.add(lineStart, Cesium.Cartesian3.multiplyByScalar(direction, t2, new Cesium.Cartesian3())));
                    }
                }

                return intersections;
            }

            // // 将笛卡尔坐标转换为经纬度
            // function cartesianToCartographic(cartesian) {
            //     return Cesium.Ellipsoid.WGS84.cartesianToCartographic(cartesian);
            // }

            // 转换经纬度
            const startCartesian = cartographicToCartesian(startLon, startLat);
            const endCartesian = cartographicToCartesian(endLon, endLat);
            const circleCartesian = cartographicToCartesian(circleLon, circleLat);

            // 半径转换为米
            const radius = radiusInKm; // * 1000;

            // 计算相交点
            const intersections = lineCircleIntersection(startCartesian, endCartesian, circleCartesian, radius);

            console.log("计算相交点:", intersections);

            // TODO zheli 在相交点处添加广告牌
            intersections.forEach((intersection, index) => {
                const position = Cesium.Ellipsoid.WGS84.cartesianToCartographic(intersection);
                window.viewer.entities.add({
                    position: Cesium.Cartesian3.fromDegrees(Cesium.Math.toDegrees(position.longitude), Cesium.Math.toDegrees(position.latitude)),
                    // billboard: {
                    //     image: require('@/assets/your-image.png'), // 使用 Vue 的 require 语法来加载本地图片
                    //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    // },
                    id: `billboard_${index}`,
                    label: {
                        text: '123',
                        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                        // scale : 1.5,       // 大小
                        // style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                        // fillColor: Cesium.Material.fromType('#00ff00'), 
                        // outlineColor: Cesium.Color.BLACK, // 轮廓线
                        // outlineWidth: 2,    // // 轮廓线宽度
                        // // showBackground: true, // 背景
                        // // backgroundColor: Cesium.Color.BLUE,
                        // pixelOffset: new Cesium.Cartesian2(0, -50), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                        // eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    
                    }
                });
            });

        },

        // 随机颜色
        // getRandomColor() {
        //     var str = '#';
        //     //一个十六进制的值的数组
        //     var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
        //     for (var i = 0; i < 6; i++) {
        //         var num = parseInt(Math.random() * 16); //随机产生0-15之间的一个数
        //         str += arr[num]; //产生的每个随机数都是一个索引,根据索引找到数组中对应的值,拼接到一起
        //     }
        //     return str;
        // },
        getRandomColor() {
            var str = '#';
            var arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

            let color;
            do {
                color = '#';
                for (var i = 0; i < 6; i++) {
                    var num = parseInt(Math.random() * 16);
                    color += arr[num];
                }
            } while (this.generatedColors.has(color)); // 检查颜色是否已存在

            // 添加新颜色到集合中
            this.generatedColors.add(color);

            return color;
        },
        generateUniqueColor() {
            // 生成并设置新的唯一颜色
            this.uniqueColor = this.getRandomColor();

        },



        // TODO 2D 添加轨迹线
        addLine(vals) {

            // 获取3d 地图中的轨迹线颜色，通过id 获取所有轨迹线实体
            // 获取所有以'point_'开头的实体
            var pointEntities = window.viewer.entities.values.filter(function (entity) {
                return entity.id.startsWith('point_') && entity.show;
            });

            // 如果没有找到任何实体，则退出函数
            if (pointEntities.length === 0) {
                // console.log("没有找到以'point_'开头的实体");
                return;
            }



            // 遍历每个线的坐标点数组
            vals.forEach((val, index) => {
                let positions = [];
                // 线条颜色
                // let pointColor = this.getRandomColor();

                let pointColor = pointEntities[index].myColor;
                // 遍历当前线的每个坐标点
                val.forEach((item) => {
                    const height = (item?.altitude != null) ? (item.altitude) : 0;
                    // 将经纬度转换为Cesium的Cartesian3坐标
                    const position = Cesium.Cartesian3.fromDegrees(item.lng, item.lat, height);
                    positions.push(position);


                });

                // console.log("遍历每个线的坐标点数组",val[0]);
                // 在viewer中添加一个新的线实体 polyline_867585332227356
                window.viewer2D.entities.add({
                    id: "polyline_" + val[0].deviceImei,
                    // show:false,
                    polyline: {
                        positions: positions,
                        // 线宽
                        width: 2,
                        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考 旋转时不会偏移
                        // 路线颜色
                        material: Cesium.Color.fromCssColorString(pointColor),
                        // 是否贴地
                        clampToGround: true,
                    },
                    //自定义属性存储线条颜色
                    color: pointColor,


                });


            });
        },

        // 位置信息  list 对象 异步等待执行
        // TODO 拼装鸽子数据 该高度
        computeFlight(lists) {
            var properties = []; // 用于存储每个轨迹的SampledPositionProperty对象

            // 遍历外部数组，每个元素是一个包含轨迹点的内部数组
            for (var i = 0; i < lists.length; i++) {
                var property = new Cesium.SampledPositionProperty(); // 为当前轨迹创建一个新的SampledPositionProperty对象
                var list = lists[i]; // 当前轨迹的点集合

                // 遍历当前轨迹的点集合
                for (var z = 0; z < list.length; z++) {
                    var item = list[z];
                    // TODO 根据json 文件不一  time  和 gpsLocationTime   互相更换
                    // height  和  altitude
                    // "lng": 120.407847,
                    // "lat": 23.408993,
                    let MendTime = moment.tz(item.gpsTime, 'YYYY-MM-DD HH:mm:ss', 'Asia/Shanghai');

                    // var thisTime = Cesium.JulianDate.fromDate(new Date(item.gpsLocationTime));
                    var thisTime = Cesium.JulianDate.fromDate(MendTime.toDate());
                    const height = (item?.altitude != null) ? (item.altitude) : 0;
                    var position = Cesium.Cartesian3.fromDegrees(
                        item.lng,
                        item.lat,
                        height
                    );

                    // 添加每一个链接点的信息，到达的时间以及坐标位置
                    property.addSample(thisTime, position);
                }
                properties.push(property); // 将当前轨迹的SampledPositionProperty对象添加到数组中
            }
            return properties; // 返回包含所有轨迹SampledPositionProperty对象的数组
        },

        // 添加模型动画
        // 异步执行
        // TODO 6666666666主方法
        async modelAnimate(list, modelGltf) {
            //添加路线 直播效果不要绘制线条
            //   let color = this.getRandomColor();
            // this.addLine(list);



            // console.log("第一只鸽子开始时间："+list[0][0].lng);

            //  TODO 开始时间 ，  应该要自定义开始时间？  eventStartTime: 0, //   开始时间
            //   eventEndTime: 0,  // 结束时间
            //   var date = new Date(list[0][0].time);
            //   var start = Cesium.JulianDate.fromDate(date); //获取第一个点的时间 
            // this.eventStartTime = window.sessionStorage.getItem('historicalTrackStartTime') || ''
            // this.eventEndTime 



            //   // 设置始时钟始时间
            //   window.viewer.clock.startTime = this.startTime.clone();
            //   // 设置时钟当前时间
            //   window.viewer.clock.currentTime = this.startTime.clone();
            //   // 时间速率，数字越大时间过的越快
            //   window.viewer.clock.multiplier = 1;
            //          调用 位置信息
            let properties = this.computeFlight(list); // 注意这里变量名改为properties，因为返回的是数组
            // console.log("properties-->", properties);


            // 为每个轨迹创建一个实体（这里仅以第一个轨迹为例）

            properties.forEach((property, index) => {


                //  在生成
                let myColor = this.getRandomColor();
                let planeModel = window.viewer.entities.add({
                    id: "point_" + list[index][0].deviceImei,    // 标识符
                    // 和时间轴关联（可以使用每个轨迹自己的时间范围，这里以第一个轨迹的时间为例）
                    availability: new Cesium.TimeIntervalCollection([

                        new Cesium.TimeInterval({
                            start: this.startTime, // 可以根据需要设置为每个轨迹自己的开始时间
                            stop: this.stopTime, // 可以根据需要设置为每个轨迹自己的停止时间
                        }),
                    ]),
                    position: property, // 使用当前轨迹的SampledPositionProperty
                    // 根据所提供的速度计算模型的朝向
                    orientation: new Cesium.VelocityOrientationProperty(property),
                    // 模型数据（可以为每个轨迹加载不同的模型，如果需要的话）
                    model: {
                        show: true,
                        uri: this.getPigeonFileName(), // 可以根据index加载不同的模型，例如：modelGltfArray[index]this.getPigeonFileName()  modelGltf this.getPigeonFileName()
                        // scale: 1.5, // 模型大小
                        // slice: 0.3,
                        minimumPixelSize: 68,  // 最大的模型像素68
                        maximumScale: 128,  // 最小的模型像素

                        // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND, // 模型贴地
                        //   runAnimations: false,//是否显示动画
                        // clampAnimations: true,//是否保持最后一针的动画
                        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                        // horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向

                    },


                    // 图片
                    //  billboard: {
                    //     image: require('@/assets/bike_online.png'),
                    //     scale : 1.5,       // 大小
                    //     // sizeInMeters: true,  // 大小是否以米为单位
                    //     horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                    //     verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直方向
                    //     // pixelOffset: new Cesium.Cartesian2(35, 0), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平方向向右偏移35像素
                    //     // alignedAxis: new Cesium.Cartesian3(-1,-10,-1) // 旋转轴 一般不用
                    //     // rotation: 1.5, // 旋转角度 3.14  顺时针旋转3.14 = 360度
                    //     // scaleByDistance: new Cesium.NearFarScalar(100, 1, 5000, 0.1), // 远近效果
                    //     // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(100000, 25, 10000000, 100), //偏移时 远近效果
                    //     // translucencyByDistance: new Cesium.NearFarScalar(1000000, 25, 10000, 0.1), // 近远效果 透明度
                    //     // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(2000, 1000000) // 显示距离 2000 显示，1000000 隐藏

                    //     },
                    // 点
                    point: {
                        show: false,
                        pixelSize: 10,
                        color: Cesium.Color.WHITE,
                        // outlineColor: Cesium.Color.BLUE,
                        // outlineWidth: 3,
                        heightReference: Cesium.HeightReference.CLAMP_TO_GROUND, // 高度参考
                        horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // 水平方向
                        verticalOrigin: Cesium.VerticalOrigin.CENTER, // 垂直方向
                    },

                    label: {
                        text: this.getLastSixDigits(list[index][0].deviceImei),
                        scale: 1.5,       // 大小
                        // 隐藏
                        show: false,
                        font: '18px sans-serif',
                        // style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 样式 
                        fillColor: Cesium.Color.WHITE,
                        // outlineColor: Cesium.Color.BLACK, // 轮廓线
                        // outlineWidth: 2,    // // 轮廓线宽度
                        // showBackground: true, // 背景
                        // backgroundColor: Cesium.Color.BLUE,
                        translucencyByDistance: new Cesium.NearFarScalar(10000, 1, 8000000, 0), // 近远效果 透明度
                        pixelOffset: new Cesium.Cartesian2(0, -100), // 偏移量 (x, y ) -y 垂直方向向上偏移35像素 ， x 水平
                        eyeOffset: new Cesium.Cartesian3(0, 0, -20), // 偏移量 (x, y , z ) Z  -20 显示在前面    


                        // font: '20px Helvetica',
                        // font: '14px sans-serif',
                        // fillColor: Cesium.Color.RED, 
                        // outlineWidth: 2,
                        // outlineColor: Cesium.Color.BLACK
                    },
                    // 线条
                    // path:{
                    //     show: true,
                    //     leadTime : 0, // 显示时间
                    //     trailTime : 15, // 尾迹时间
                    //     resolution : 1, // 时间分辨率
                    //     width : 2, // 宽度
                    //     material : new Cesium.PolylineGlowMaterialProperty({ // 材质
                    //         glowPower : 0.2,
                    //         color : Cesium.Color.RED, 
                    //     }),

                    // }
                    // path:{
                    //     leadTime: 0,
                    //     resolution:1,
                    //     material: new Cesium.PolylineGlowMaterialProperty({
                    //         color: Cesium.Color.WHITE,
                    //         glowPower: 0.2,

                    //     }),
                    //     width: 10
                    // }
                    path: {
                        show: true,
                        leadTime: 0,
                        resolution: 120, // 时间分辨率
                        // material: new Cesium.ColorMaterialProperty(Cesium.Color.WHITE ),
                        material: Cesium.Color.fromCssColorString(myColor),
                        width: 3
                    },
                    // 添加自定颜色属性
                    myColor: myColor,

                });


            });

            // TODO 变成鸽子111111111111111 判断session中是否有实体数据

            console.log('跳转实体');
            this.selectFirstRow();




        },
        // TODO 截取设备号保留后6位字符
        getLastSixDigits(str) {
            // 检查字符串长度是否足够
            if (str.length >= 6) {
                return str.slice(-6); // 从字符串末尾开始截取6个字符
            } else {
                // 如果字符串长度不足6，直接返回整个字符串
                return str;
            }
        },
        //创建模型
        addModel(url, height) {
            window.viewer.entities.removeAll();

            const position = Cesium.Cartesian3.fromDegrees(
                104.03697, 34.43811,
                height
            );
            const heading = Cesium.Math.toRadians(135); // 指向
            const pitch = 0;    // 视角
            const roll = 0;     // 偏移
            const hpr = new Cesium.HeadingPitchRoll(heading, pitch, roll);
            const orientation = Cesium.Transforms.headingPitchRollQuaternion(
                position,
                hpr
            );

            const entity = window.viewer.entities.add({
                name: url,
                position: position,
                orientation: orientation, // 方向
                model: {
                    uri: url,
                    minimumPixelSize: 328,  // 最小的模型像素
                    maximumScale: 20000,
                    // runAnimations: false,//是否显示动画
                    // clampAnimations: true,//是否保持最后一针的动画
                    //裁剪 ok
                    // clippingPlanes : new Cesium.ClippingPlaneCollection({
                    //     planes: [
                    //         new Cesium.ClippingPlane(new Cesium.Cartesian3(0,0,1), 0),

                    //     ],
                    //     edgeWidth: 1,
                    //     edgeColor:  Cesium.Color.RED,
                    //     enabled: true,
                    //     edgeMaterial: new Cesium.PolylineOutlineMaterialProperty({
                    //         color: Cesium.Color.RED,
                    //         outerWidth:1,
                    //         outlineColor: Cesium.Color.BLACK,

                    //     }),
                    // }),

                },
            });

            window.viewer.trackedEntity = entity;
        },
        // cesium时钟时间格式化函数
        CesiumTimeFormatter(datetime, viewModel) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")} : ${second.padStart(2, "0")}`;
        },

        // cesium时钟日期格式化函数 年月日 改成 - - -
        CesiumDateFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}`;
        },

        // cesium时间轴格式化函数 年月日时分
        CesiumDateTimeFormatter(datetime, viewModel, ignoredate) {
            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}-${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`;
        },

        // cesium时间轴格式化函数 年月日时分秒
        CesiumDateTimeXFormatter(datetime, viewModel, ignoredate) {

            var julianDT = new Cesium.JulianDate();
            Cesium.JulianDate.addHours(datetime, 8, julianDT);
            var gregorianDT = Cesium.JulianDate.toGregorianDate(julianDT);
            let hour = gregorianDT.hour + "";
            let minute = gregorianDT.minute + "";
            let second = gregorianDT.second + "";
            return `${gregorianDT.year}-${gregorianDT.month}-${gregorianDT.day}-${hour.padStart(2, "0")}:${minute.padStart(2, "0")}:${second.padStart(2, "0")}`;
        },




    }

}
</script>

<style lang="less" scoped>
.body_div {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
}

#container {

    width: 100%;
    height: 100%;
}

/* .amap-ctrl-list-layer{
    right: 1108px;
  } */



.botOtherBox {


    position: absolute;
    width: 100%;
    bottom: 30px;
    /* left: 300px; */
    /* height: 60px; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* margin: 0 auto; */
    -ms-flex-wrap: wrap;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

}

.main_flex {
    display: flex;
    flex-wrap: wrap;
}

.botOtherItem {
    padding: 8px 18px;
    background: rgba(0, 9, 18, .5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, .75);
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    margin-right: 15px;
    line-height: 1;
    cursor: pointer;
    user-select: none;
}

.botOtherItem:hover {

    color: #1af2ff;

}




.input-card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    width: 22rem;
    border-width: 0;
    border-radius: 0.4rem;
    box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0.75rem 1.25rem;
}

.input-card .btn {

    margin-right: 1.2rem;
    width: 9rem;
}

.input-card .btn:last-child {
    margin-right: 0;
}

/** 排名样式 */
.playBox {
    background: rgba(0, 9, 18, 0.5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, .75);
    border-radius: 4px;
    user-select: none;
    height: 42px;
    z-index: 99;
}

.main_flex_ar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.playBoxItem {
    width: 146px;
    height: 42px;
    padding: 0 15px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
    line-height: 1;
    position: relative;
}

.playBoxItem:hover {
    color: #1af2ff;
}


.main_flex_be {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.imgBox {
    width: 31px;
    height: 31px;
    margin-right: 8px;
}

img {
    width: 100%;
    height: 100%;
}

.icons {
    font-size: 20px;
    transition: all .3s;
}

.el-icon {

    height: 1em;
    width: 1em;
    line-height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    fill: currentColor;
    color: #fff;
    font-size: inherit;
    z-index: 100;
}

.botBox {
    display: block;
    /* 改为block以使用绝对定位 */
    position: absolute;
    bottom: 100%;
    /* 初始位置在imgBox的下方，但我们将通过JS调整它 */
    left: 0;
    width: 146px;
    /* max-height不再需要，因为我们将使用v-if来控制显示和隐藏 */
    transition: all 0.3s;
    background: rgba(0, 9, 18, 0.5);
    box-shadow: 0px 2px 9px 0px rgba(0, 33, 71, 0.75);
    border-radius: 4px;
    font-size: 24px;
    color: #fff;
    overflow: hidden;
    /* 初始时隐藏，通过v-if控制 */
    /* 注意：由于我们使用了v-if，max-height属性不再需要 */
}

/* 添加过渡效果 */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.botBtnItem {
    width: 100%;
    padding: 12px 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);

}

.botBtnItem:hover {
    color: #1af2ff;
}


/* 梯队数据样式 */
.echelonBox.on {
    transform: translate(0);
}

// TODO 表格样式
.echelonBox {
    // padding: 20px 30px 10px;
    width: 340px;
    background: rgba(0, 0, 0, .05);
    border-radius: 8px;
    font-size: 22px;
    color: #fff;
    position: fixed;
    top: 120px;
    right: 15px;
    transition: all .3s;
    transform: translate(200%);
    // user-select: none;
}


.el-icon {
    --color: inherit;
    height: 1em;
    width: 1em;
    line-height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    fill: currentColor;
    color: var(--color);
    font-size: inherit;
}

.titBoxImg {
    width: 30px;
    height: 30px;
    margin-right: 15px;
}

.closePlay2 {
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    font-size: 22px;
    width: 30px;
    height: 30px;
    // background: #000;
    // opacity: 0;
    // border-radius: 50%;
    // transition: all .3;
    // z-index: 99;
}


.main_flex_ar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.infoBoxTit {
    margin-bottom: 20px;
    align-items: center;
    font-size: 28px;
    color: #fff;
    text-shadow: 0px 0px 10px #fff;
    line-height: 1;
}

.echelonItem {
    margin-bottom: 20px;
}

.main_flex_be {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.echelonTit {
    font-size: 20px;
    color: #fff;
}

.echelonNum {
    font-size: 28px;
    color: #fff;
}

.main_flex {
    display: flex;
    flex-wrap: wrap;
}

.homingTime {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
}

.echelonCont {
    padding-right: 50px;
    width: 100%;
}

/* 赛鸽排名样式 */


.index_one {
    color: #fec412;
    display: none;
}

.index_two {
    color: #b4c0c7;
    display: none;
}

.index_three {
    color: #714e3a;
    display: none;
}


::v-deep {

    // .el-table th.el-table__cell {
    //     user-select: initial;
    //     }
    .el-table {
        margin-top: 1px;
        height: 419px;
        width: 100%;
        overflow-x: auto;
        overflow-y: auto;
    }

    .el-table,
    .el-table__expanded-cell {
        background-color: #ffffff00;
    }

    .el-table th.el-table__cell {
        background-color: #ffffff00;
    }

    .el-table thead {
        color: #ffffff;
    }

    .el-table tr {
        background-color: #ffffff00;
        color: #ffffff;
    }

    .el-table__empty-text {

        color: #ffffff;
    }

    //  .el-table--enable-row-hover .el-table__body tr:hover {
    //     background-color: #052531; 
    // }
    // 鼠标移上去
    .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
        background-color: #3498dbcb;
    }

    // // 设置滚动条颜色
    // .el-table__body-wrapper {
    //     scrollbar-width: thin; /* 或 'auto' */
    //     scrollbar-color: #00a0e9 #ffffff00; /* 滑块颜色 轨道颜色 */
    // }

    // .el-table--scrollable-y .el-table__body-wrapper ::-webkit-scrollbar {
    //     width: 10px; /*滚动条的宽*/
    //     height: 10px; /*滚动条的高*/
    // }
    // .el-table .el-table__body-wrapper::-webkit-scrollbar-track {
    //     box-shadow: 0px 1px 3px #0558f3 inset; /*滚动条的背景区域的内阴影*/
    //     border-radius: 10px; /*滚动条的背景区域的圆角*/
    //     background-color: #4a0735; /*滚动条的背景颜色*/
    // }

    // 全屏
    .cesium-viewer-fullscreenContainer {
        position: absolute;
        /* bottom: 0; */
        top: 8px;
        right: 48px;
        padding: 0;
        width: 29px;
        height: 29px;
        overflow: hidden;
    }



    // 隐藏小地图时间控件
    #cesiumContainer2D .cesium-viewer-animationContainer {
        display: none;
    }

    // 改变时间进度条默认样式   !important 优先级 这里设置的优先
    #cesiumContainer .cesium-viewer .cesium-viewer-timelineContainer {
        right: 0px !important;
        left: 169px !important;

    }

    // 3D时间控件在2d地图上面
    #cesiumContainer .cesium-viewer-animationContainer {
        z-index: 2;
        width: 169px !important;
        height: 112px !important;
    }


    .el-form--inline .el-form-item__content {

        width: 100%;
    }

    .el-form-item {
        width: 100%;
    }


    // 自定义switch样式
    .switchStyle .el-switch__label {
        position: absolute;
        display: none;
        color: #fff;
    }

    .el-switch__core {
        background-color: rgba(166, 166, 166, 1);
    }

    .switchStyle .el-switch__label--left {
        z-index: 9;
        left: 20px;
    }

    .switchStyle .el-switch__label--right {
        z-index: 9;
        left: 4px;
    }

    .switchStyle .el-switch__label.is-active {
        display: block;
    }

    .switchStyle.el-switch .el-switch__core,
    .el-switch .el-switch__label {
        width: 60px !important;
    }
}


// .el-table {
//     /deep/ .el-table__body-wrapper::-webkit-scrollbar {
//         width: 14px;
//         /*滚动条宽度*/
//         height: 14px;
//         /*滚动条高度*/
//     }

//     /*定义滚动条轨道 内阴影+圆角*/
//     /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
//         box-shadow: 0px 1px 3px #071e4a inset;
//         /*滚动条的背景区域的内阴影*/
//         border-radius: 10px;
//         /*滚动条的背景区域的圆角*/
//         background-color: #ffffff00;
//         /*滚动条的背景颜色*/
//     }

//     /*定义滑块 内阴影+圆角*/
//     /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
//         box-shadow: 0px 1px 3px #00a0e9 inset;
//         /*滚动条的内阴影*/
//         border-radius: 10px;
//         /*滚动条的圆角*/
//         background-color: #00a0e9;
//         /*滚动条的背景颜色*/
//     }
// }


// 地图切换
.mapSwitchBox {
    position: absolute;
    right: 16px;
    bottom: 75px;
    z-index: 100;
}

// 地图按钮
.mapSwitch {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 36px;
    height: 36px;
    border-radius: 20px;
    background-color: hsla(0, 0%, 100%, .6666666666666666);
    padding: 2px 2px 2px 4px;
    margin-top: 10px;
}

// 比赛数据按钮
.mapGameBox {
    position: absolute;
    left: 16px;
    top: 15px;
    z-index: 100;
}

.mapSwitchItem {
    width: 28px;
    height: 28px;
}

// 2d地图
#cesiumContainer2D {
    position: relative;
    width: 200px;
    height: 200px;

    /* 小地图的内部Cesium容器高度 */
    border-radius: 50%;
    /* 圆角设置为50%，使容器呈现为圆形 */
    overflow: hidden;
    /* 隐藏超出圆形区域的内容 */
    // border: 6px solid red;
    border-width: 5px;
    border-style: solid;

    /* 添加红色边框，边框宽度为5px */

    //   user-select: none; /* 禁止文本选择 */
    // -webkit-user-drag: none; /* 禁止元素被拖拽 */
    // -moz-user-select: none; /* Firefox 私有属性，禁止文本选择 */
    // -ms-user-select: none; /* Internet Explorer/Edge 私有属性，禁止文本选择 */
    pointer-events: none;
    /* 禁止鼠标事件，但注意这也会影响点击事件等 */
    /* 其他样式... */

}

.cesium2D-wrapper {
    // position: relative;
    /* 确保子元素能够相对于此元素定位 */
    // width: 200px;
    /* 根据需要调整小地图的宽度 */
    // height: 200px;
    /* 根据需要调整小地图的高度 */
    position: absolute;

    bottom: 181px;
    left: 1px;
    z-index: 1;

}

.mask-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.5);
    pointer-events: none;
}

// 左边赛事列表
.mapGameListBox {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 100;
    width: 40%;
    height: 100%;
    background-color: #052531c2;
    // border: 1px red solid;

    // background: rgba(0, 0, 0, 0.137);
    border-radius: 8px;
    // font-size: 22px;
    // color: #fff;
    // // position: fixed;
    // // top: 120px;
    // // right: 15px;
    // transition: all .3s;
    // // transform: translate(200%);

}






/**移动端样式  */
@media screen and (max-width: 768px) {

    // 按钮
    .mapSwitchBox {
        position: absolute;
        right: 5px;
        bottom: 55px;
        z-index: 100;
    }

    // 2d小地图
    #cesiumContainer2D {
        position: relative;
        width: 100px;
        height: 100px;
    }

    .cesium2D-wrapper {
        position: absolute;
        top: auto;
        bottom: 125px;
        left: 1px;
        z-index: 1;

    }

    // 表格数据宽度
    .el-table {
        width: 100%;
        height: 163px;

    }

    .echelonBox {
        // padding: 20px 30px 10px;
        width: 320px;
        background: rgba(0, 0, 0, .05);
        border-radius: 8px;
        font-size: 22px;
        color: #fff;
        position: fixed;
        top: 120px;
        right: 5px;
        transition: all .3s;
        transform: translate(200%);
        // user-select: none; // 禁止文本选择
    }

    // 赛事列表样式
    // 赛事列表
    .mapGameListBox {

        width: 88%;

    }
}
</style>