<template>
    <div class="bigScreen">
        <ScaleBox>
            <div class="main-wraper">
                <el-container>
                    <!--Header -->
                <el-header>
                        
                        <img id='logoImg' style="height:55px;padding-left: 5px;padding-top: 5px;"
                            src="/mapGLB/logo_b.png" />

                        <div class='raceNameLabel' style="color: #fff">比赛名称</div>
                        <div class='raceNameLabel' style="color: #fff">2024决赛</div>

                        <div style='float: left; margin-left: 50px; margin-top: 30px; font-size: 24px; display:none;'>/</div>

                        
                </el-header>
                <!--Main -->
                <el-main>
                    <div style="height: 1500px; background-color: blue;"></div>
                </el-main>

                <!-- Footer-->
                <el-footer>Footer</el-footer>
                </el-container>
            </div>
            
         </ScaleBox>
    </div>
</template>

<script>
// 引入ccs 适应手机屏幕 消除原有样式
import '@/assets/css/track.css';
import ScaleBox from "./sacleBox.vue";


export default {
   
    props: {
        msg: String
    },
    components: {
    ScaleBox,
    },
    data() {
        return {
            // msg: 'Welcome to Your Vue.js App'
        }
    },
    mounted() {
       }

    
}
</script>


<style scoped>  

.bigScreen{
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    font-size: 14px;
    
    background-color: #ffffff;

    .main-wraper{
        user-select: none;
        width: 1920px;
        height: 1080px;
        margin: 0 auto;
    }
}

 
 .el-header {
    background-color: #052531;
    color: #ffffff;
    /* text-align: center; */
    line-height: 70px;
  }

.el-footer {
    background-color: #052531;
    color: #ffffff;
    /* text-align: center; */
    line-height: 60px;
  }

  #logoImg {
    /* display: inline-block; */
    float: left;
    max-width: 200px;
    max-height: 120px;
}
  .raceNameLabel {
    /* display: inline-block; */
    float: left;
    font-size: 18px;
    margin-left: 20px;
    margin-top: 30px;
    line-height: normal;
}
</style>