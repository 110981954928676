import { render, staticRenderFns } from "./pigeonRacingPlatform3D.vue?vue&type=template&id=6383aaec&scoped=true"
import script from "./pigeonRacingPlatform3D.vue?vue&type=script&lang=js"
export * from "./pigeonRacingPlatform3D.vue?vue&type=script&lang=js"
import style0 from "./pigeonRacingPlatform3D.vue?vue&type=style&index=0&id=6383aaec&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6383aaec",
  null
  
)

export default component.exports